import superagent from 'superagent';
import Cookie from 'universal-cookie';


function addAuthHeader(type, endpoint) {
    const csrfToken = new Cookie().get('csrftoken');

    return superagent[type](endpoint)
        .set('X-CSRFToken', csrfToken);
}

const Request = (() => {
    return {
        get: (endpoint) => addAuthHeader('get', endpoint),
        post: (endpoint) => addAuthHeader('post', endpoint),
        patch: (endpoint) => addAuthHeader('patch', endpoint),
        put: (endpoint) => addAuthHeader('put', endpoint),
        delete: (endpoint) => addAuthHeader('delete', endpoint),
    };
})();

export default Request;