import { Map } from 'immutable';
import Cookies from 'universal-cookie';

import { AUTH } from './AuthContants';


const getMyAccount = () => {
    const userFromCookies = new Cookies().get('myAccount');

    return Map({ ...userFromCookies });
};

const saveAccount = (myAccount = {}) => {
    return new Cookies().set('myAccount', myAccount, { path: '/', sameSite: true });
};

const defaultState = () => Map({
    loginStateReadFromServer: false,
    loginStateProcessing: false,
    loginProcessing: false,
    loginError: null,

    myAccount: getMyAccount(),
});

export default function Auth(state = defaultState(), action) {
    switch (action.type) {
        case AUTH.LOGIN_START:
            return state.merge({
                loginProcessing: true,
                loginError: null,
            });

        case AUTH.LOGIN_SUCCESS:
            saveAccount(action.myAccount);

            return state.merge({
                myAccount: Map({ ...action.myAccount }),
                loginStateReadFromServer: true,
                loginProcessing: false,
            });

        case AUTH.LOGIN_ERROR:
            saveAccount();

            return state.merge({
                loginStateReadFromServer: false,
                loginProcessing: false,
                loginError: action.error,
                myAccount: Map({}),
            });

        case AUTH.LOGIN_STATE_TEST_START:
            return state.merge({
                loginStateProcessing: true,
                loginError: null,
            });

        case AUTH.LOGIN_STATE_TEST_SUCCESS:
            return state.merge({
                loginStateReadFromServer: true,
                loginStateProcessing: false,
            });

        case AUTH.LOGIN_STATE_TEST_ERROR:
            saveAccount();

            return state.merge({
                loginStateReadFromServer: false,
                loginStateProcessing: false,
                loginError: action.error,
                myAccount: Map({}),
            });

        case AUTH.LOGOUT:
            saveAccount();

            return state.merge({
                loginStateReadFromServer: false,
                myAccount: Map({}),
            });

        default:
            return state;
    }
}