import React, { Component } from 'react';
import {
    withRouter,
    Switch,
    Route,
} from 'react-router-dom';
import { Layout } from 'antd';

import Policy from 'routes/policy/Policy';
import Home from 'routes/home/HomeContainer';
import Shop from 'routes/shop/ShopContainer';
import Store from 'routes/store/StoreContainer';
import Footer from '../components/footer/Footer';
import Checkout from 'routes/checkout/CheckoutContainer';
import Header from '../components/header/HeaderContainer';
import DetailProduct from 'routes/product/DetailProductContainer';

import './App.scss';

class App extends Component {

    render() {
        return (
            <Layout className='App'>
                <div className='App-content'>
                    <div className='App-content-body'>
                        <Header />
                        <Switch>
                            <Route exact path='/store' component={Store} />
                            <Route exact path='/product/:productId' component={DetailProduct} />
                            <Route exact path='/validation' component={Checkout} />
                            <Route path='/policy' component={Policy} />
                            <Route path='/shop' component={Shop} />
                            <Route path='/' component={Home} />
                        </Switch>
                    </div>
                    <Footer />
                </div>
            </Layout>
        );
    }
}

export default withRouter(App);
