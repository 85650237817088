import { postLogin, postLogout, getCheck } from './AuthClient';
import { AUTH } from './AuthContants';

export function login({ username, password }) {
    return (dispatch) => {
        dispatch({ type: AUTH.LOGIN_START });

        return postLogin({ username, password })
            .then(myAccount => {
                dispatch({
                    type: AUTH.LOGIN_SUCCESS,
                    myAccount,
                });
            })
            .catch(error => {
                dispatch({
                    type: AUTH.LOGIN_ERROR,
                    error,
                });
            });
    };
}

export function logout() {
    return (dispatch) => {
        return postLogout()
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                dispatch({ type: AUTH.LOGOUT });
            });
    };
}

export function checkLoginState() {
    return (dispatch) => {
        dispatch({ type: AUTH.LOGIN_STATE_TEST_START });

        return getCheck()
            .then(() => dispatch({ type: AUTH.LOGIN_STATE_TEST_SUCCESS }))
            .catch((error) => {
                return dispatch({
                    type: AUTH.LOGIN_STATE_TEST_ERROR,
                    error,
                });
            });
    };
}