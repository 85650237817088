import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import {
    ShoppingCartOutlined,
    LeftOutlined,
} from '@ant-design/icons';

import banner from './banner.jpg';

import './Header.scss';

const HIDE_CART = ['/', '/validation', '/payment', '/login'];
const HIDE_BACK = ['/', '/shop', '/login'];

class Header extends Component {
    static propTypes = {
        cart: PropTypes.object,
        fetchingCart: PropTypes.bool,
        fetchCart: PropTypes.func,
        maintenance: PropTypes.object,
        fetchMaintenance: PropTypes.func,
    };

    componentDidMount() {
        this.props.fetchCart();
        this.props.fetchMaintenance();
    }

    renderBack() {
        const location = window.location.pathname;
        const cx = HIDE_BACK.indexOf(location) !== -1 ? 'mod-hide' : '';

        return (
            <Link
                to='/shop'
                className={`Header-back ${cx}`}
            >
                <LeftOutlined />&nbsp;
                Retour à la boutique
            </Link>
        )
    }

    renderCart() {
        const {
            cart,
            fetchingCart,
        } = this.props;

        if (!cart.articles || cart.articles.length === 0) {
            return false;
        }

        const count = cart.articles.reduce((acc, value) => acc + value.quantity, 0)
        const text = count > 1 ? 'articles' : 'article';
        const location = window.location.pathname;

        let cx = HIDE_CART.indexOf(location) !== -1 ? 'mod-hide' : '';

        return (
            <div className='Header-cart-container'>
                <Spin spinning={fetchingCart}>
                    <Link
                        to='/validation'
                        className={`Header-cart ${cx}`}
                    >
                        {`${count} ${text}`}  <ShoppingCartOutlined />
                    </Link>
                </Spin>
            </div>

        )
    }

    render() {
        const { pathname } = window.location;
        const { maintenance } = this.props;
        if (pathname === '/store') {
            return false;
        }

        //During construct
        let linkTo = '/shop';
        if (pathname === '/shop') {
            linkTo = '/';
        }

        return (
            <div className='Header'>
                <Link to={linkTo}>
                    <h1>
                        <img
                            className='Header-banner'
                            src={banner}
                            alt='Des nouvelles de la terre'
                        />
                    </h1>
                </Link>
                <p className='Header-maintenance'>{maintenance.is_active ? maintenance.description : ''}</p>
                <div className='Header-nav'>
                    {this.renderBack()}
                    {this.renderCart()}
                </div>
            </div>
        );
    }
}
export default Header;