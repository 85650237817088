export const AUTH = {
    LOGIN_START: 'AUTH_LOGIN_START',
    LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
    LOGIN_ERROR: 'AUTH_LOGIN_ERROR',

    LOGIN_STATE_TEST_START: 'AUTH_LOGIN_STATE_TEST_START',
    LOGIN_STATE_TEST_SUCCESS: 'AUTH_LOGIN_STATE_TEST_SUCCESS',
    LOGIN_STATE_TEST_ERROR: 'AUTH_LOGIN_STATE_TEST_ERROR',

    LOGOUT: 'AUTH_LOGOUT',
};