import { connect } from 'react-redux';

import { checkLoginState, logout } from 'modules/auth/AuthActions';

import Store from './Store.jsx';


const mapStateToProps = (state) => {
    const {
        loginStateReadFromServer,
        loginStateProcessing,
        myAccount,
    } = state.auth.toJS();

    return {
        loginStateReadFromServer,
        loginStateProcessing,
        myAccount,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        checkLoginState() {
            dispatch(checkLoginState());
        },
        logout() {
            dispatch(logout());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Store);