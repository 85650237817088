import { connect } from 'react-redux';

import { login } from 'modules/auth/AuthActions';
import Login from './Login.jsx';


const mapStateToProps = (state) => {
    const loginError = state.auth.get('loginError');

    return {
        error: loginError,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        login({ username, password }) {
            dispatch(login({ username, password }));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);