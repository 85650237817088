import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Input } from 'antd';

import './Maintenance.scss';

class Maintenance extends Component {
    static propTypes = {
        maintenance: PropTypes.object,
        editMaintenance: PropTypes.func,
        enableMaintenance: PropTypes.func,
        disableMaintenance: PropTypes.func,
    };


    onChange = () => {
        const {
            maintenance,
            enableMaintenance,
            disableMaintenance,
        } = this.props;

        if (maintenance.is_active) {
            disableMaintenance(maintenance.id);
        }  else {
            enableMaintenance(maintenance.id);
        }
    }

    onEdit = (event) => {
        const { editMaintenance, maintenance } = this.props;
        editMaintenance(maintenance.id, event.target.value);
    }

    render() {
        const { maintenance } = this.props;

        return (
            <div className='Maintenance'>
                <h2>Maintenance</h2>

                <div className='Maintenance-form'>
                    <div>
                        Etat de la maintenance: &nbsp;
                        <Switch
                            loading={maintenance.loading}
                            defaultChecked={maintenance.is_active}
                            onChange={this.onChange}
                            checkedChildren='On'
                            unCheckedChildren='Off'
                        />
                    </div>
                    <div>
                        Description:
                        <Input.TextArea
                            rows={12}
                            onChange={this.onEdit}
                            defaultValue={maintenance.description}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Maintenance;