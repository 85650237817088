import { Map } from 'immutable';

import { PRODUCTS } from './ProductsConstants';

const defaultPaginator = () => Map({
    total_elements: 0,
    page_size: 200,
    current: 1,
});

const defaultState = () => Map({
    filter: null,
    product: Map({}),
    fetchingProduct: false,

    products: Map({}),
    paginator: defaultPaginator(),
    fetchingProducts: false,
    creating: false,
});

export default function Products(state = defaultState(), action) {
    switch (action.type) {
        case PRODUCTS.ADD_START:
            return state.merge({
                creating: true,
            });
        case PRODUCTS.ADD_ERROR:
            return state.merge({
                creating: false,
            });
        case PRODUCTS.ADD_SUCCESS:
            return state.setIn(['products', action.product.id], action.product).merge({
                creating: false,
            });;

        case PRODUCTS.FETCH_START:
            let fetching = false;
            if (state.get('products').size > 0) {
                fetching = true;
            }
            return state.merge({
                fetchingProducts: fetching,
            });

        case PRODUCTS.FETCH_SUCCESS:
            return state.merge({
                products: action.products.reduce((acc, product) => {
                    acc[product.id] = product;
                    return acc;
                }, {}),
                paginator: action.paginator,
                fetchingProducts: false,
            });

        case PRODUCTS.FETCH_ERROR:
            return state.merge({
                products: Map({}),
                paginator: defaultPaginator(),
                fetchingProducts: false,
            });

        case PRODUCTS.FETCH_ONE_START:
            return state.merge({
                product: Map({}),
                fetchingProduct: true,
            });

        case PRODUCTS.FETCH_ONE_SUCCESS:
            return state.merge({
                product: action.product,
                fetchingProduct: false,
            });

        case PRODUCTS.UPDATE_SUCCESS:
            return state.setIn(['products', action.product.id], action.product)

        case PRODUCTS.DELETE_START:
            return state.deleteIn(['products', action.id]);


        case PRODUCTS.CHANGE_TYPE:
            return state.merge({
                filter: action.filter,
                products: Map({}),
                paginator: defaultPaginator(),
            });
        default:
            return state;
    }
}