import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Form, Input, Button, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import banner from '../../components/header/banner.jpg';

import './Login.scss';


class Login extends Component {
    static propTypes = {
        error: PropTypes.object,

        login: PropTypes.func,
    };

    componentDidUpdate(prevProps) {
        const { error } = this.props;

        if (!prevProps.error && error) {
            if (error.status && error.status === 401) {
                message.error('Incorrect username or password.');
            } else {
                message.error('Oops something went wrong… Please retry.');
            }
        }
    }

    onSubmit = ({ username, password }) => {
        this.props.login({ username, password });
    };

    render() {
        const usernameRules = [{
            message: 'Please input your Username!',
            required: true,
        }];
        const passwordRules = [{
            message: 'Please input your Password!',
            required: true,
        }];

        return (
            <div className='Login'>
                <img
                    className='Login-banner'
                    src={banner}
                    alt='Des nouvelles de la terre'
                />
                <Form
                    initialValues={{ remember: true }}
                    onFinish={this.onSubmit}
                    className='Login-form'
                >
                    <Form.Item
                        rules={usernameRules}
                        name='username'
                    >
                        <Input
                            prefix={<UserOutlined className='Login-input-icon' />}
                            placeholder='Username'
                        />
                    </Form.Item>
                    <Form.Item
                        rules={passwordRules}
                        name='password'
                    >
                        <Input
                            prefix={<LockOutlined className='Login-input-icon' />}
                            placeholder='Password'
                            type='password'
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            className='Login-form-button'
                            htmlType='submit'
                            type='primary'
                        >
                            Sign in
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

export default Login;
