import { PAYMENT } from './PaymentConstants';
import { COMMANDS } from '../commands/CommandsConstants';
import {
    purchase,
} from './PaymentClient';

export function startPurchase() {
    return (dispatch) => {
        return dispatch({
            type: PAYMENT.PURCHASE_START,
        });
    };
}

export function successPurchase(commandId, callback) {
    return (dispatch) => {
        return purchase(commandId)
            .then((response) => {
                dispatch({
                    type: COMMANDS.GET_COMMAND_SUCCESS,
                    command: response,
                });
                dispatch({
                    type: PAYMENT.PURCHASE_SUCCESS,
                });
                callback();
            })
            .catch(error => {
                dispatch({
                    type: PAYMENT.PURCHASE_ERROR,
                    error: 'Votre compte n\'a pas été débité: une des pièces de votre commande n\'est plus disponible.',
                });
            });
    };
}

export function errorPurchase(error) {
    return (dispatch) => {
        return dispatch({
            type: PAYMENT.PURCHASE_ERROR,
            error: error,
        });
    };
}

export function resetCommand() {
    return (dispatch) => {
        return dispatch({
            type: PAYMENT.PURCHASE_CLEAN,
        });
    };
}