export const CART = {
    FETCH_START: 'CART_FETCH_START',
    FETCH_SUCCESS: 'CART_FETCH_SUCCESS',
    FETCH_ERROR: 'CART_FETCH_ERROR',

    ADD_START: 'CART_ADD_START',
    ADD_SUCCESS: 'CART_ADD_SUCCESS',
    ADD_ERROR: 'CART_ADD_ERROR',

    ADD_SHIPPING_FEE_START: 'CART_ADD_SHIPPING_FEE_START',
    ADD_SHIPPING_FEE_SUCCESS: 'CART_ADD_SHIPPING_FEE_SUCCESS',
    ADD_SHIPPING_FEE_ERROR: 'CART_ADD_SHIPPING_FEE_ERROR',

    UPDATE_START: 'CART_UPDATE_START',
    UPDATE_SUCCESS: 'CART_UPDATE_SUCCESS',
    UPDATE_ERROR: 'CART_UPDATE_ERROR',

    CLEAN: 'CART_CLEAN',
};