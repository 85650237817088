import React from 'react';
import {
    useStripe,
    useElements,
    CardElement,
} from '@stripe/react-stripe-js';

import CardSection from '../cardsection/CardSection';

import './CheckoutForm.scss';


const CheckoutForm = props => {
    const commandId = props.commandId;
    const total = props.total;
    const disabled = props.disabled;
    const clientKey = props.clientKey;
    const clientInfo = props.clientInfo;
    const purchasing = props.purchasing

    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        props.onStart()


        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const result = await stripe.confirmCardPayment(`${clientKey}`, {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: clientInfo,
            }
        });

        if (result.error) {
            // Show error to your customer (e.g., insufficient funds)
            props.onError(result.error.message)
        } else if (result.paymentIntent.status === 'succeeded') {
            props.onSuccess(commandId)

            // Show a success message to your customer
            // There's a risk of the customer closing the window before callback
            // execution. Set up a webhook or plugin to listen for the
            // payment_intent.succeeded event that handles any business critical
            // post-payment actions.
        }
    };

    return (
        <form
            className='CheckoutForm'
            onSubmit={handleSubmit}
        >
            <CardSection />
            <button
                className='CheckoutForm-submit'
                disabled={!stripe || purchasing || disabled}
            >
                Payer {total.toFixed(2)} €
            </button>
        </form>
    );
}
export default CheckoutForm;