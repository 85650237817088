export const MAINTENANCE = {
    FETCH_START: 'MAINTENANCE_FETCH_START',
    FETCH_SUCCESS: 'MAINTENANCE_FETCH_SUCCESS',
    FETCH_ERROR: 'MAINTENANCE_FETCH_ERROR',

    EDIT_START: 'MAINTENANCE_EDIT_START',
    EDIT_SUCCESS: 'MAINTENANCE_EDIT_SUCCESS',
    EDIT_ERROR: 'MAINTENANCE_EDIT_ERROR',

    ENABLE_START: 'MAINTENANCE_ENABLE_START',
    ENABLE_SUCCESS: 'MAINTENANCE_ENABLE_SUCCESS',
    ENABLE_ERROR: 'MAINTENANCE_ENABLE_ERROR',

    DISABLE_START: 'MAINTENANCE_DISABLE_START',
    DISABLE_SUCCESS: 'MAINTENANCE_DISABLE_SUCCESS',
    DISABLE_ERROR: 'MAINTENANCE_DISABLE_ERROR',
};