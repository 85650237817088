import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons'
import { Config } from '../../config'

import './Total.scss';

class Total extends Component {
    static propTypes = {
        total: PropTypes.number,
        shipping: PropTypes.number,
        onlySum: PropTypes.bool,
    };

    renderNumber(text, value, mode) {
        const { onlySum } =this.props;
        if (!value || onlySum) {
            return false;
        }

        if (mode === 'shipping') {
            return (
                <p>
                    <Tooltip title={`Les frais de port sont facturés ${Config.SHIPPING_BASE} € pour le premier article, puis ${Config.SHIPPING_ADD} € pour chaque nouvel article ajouté au panier.`}>
                        <InfoCircleOutlined />
                    </Tooltip>
                    &nbsp;
                    <span>{text} {value.toFixed(2)} €</span>
                </p>
            )
        }

        return (
            <p>{text}: {value.toFixed(2)} €</p>
        );
    }

    render() {
        const {
            total,
            shipping,
        } = this.props;

        const sum = (total || 0) + (shipping || 0);
        if (total === 0) {
            return false;
        }

        return (
            <div className='Total'>
                { this.renderNumber('Sous total', total) }
                { this.renderNumber('Livraison', shipping, 'shipping') }
                <p className='Total-sum'>Total {(sum).toFixed(2)} €</p>
            </div>
        );
    }
}

export default Total;