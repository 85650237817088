import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Button.scss';

class Button extends Component {

    static propTypes = {
        children: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.any),
            PropTypes.any,
        ]).isRequired,
        onClick: PropTypes.func,
        disabled: PropTypes.bool,
        type: PropTypes.oneOf([
            'primary',
        ]),
        size: PropTypes.oneOf([
            'small',
            'medium',
            'large',
        ]),
    };

    static defaultProps = {
        size: 'large',
        className: '',
        type: 'primary',
    };


    render() {
        const {
            disabled,
            onClick,
            children,
            type,
        } = this.props;
        const cx = `mod-${type}`;
        const size = `size-${this.props.size}`;

        const classNameTotal = `Button ${cx} ${size}`;

        return (
            <button
                className={classNameTotal}
                disabled={disabled}
                onClick={onClick}
            >
                {children}
            </button>
        );
    }
}
export default Button;