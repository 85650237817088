import { connect } from 'react-redux';

import Thanks from './Thanks.jsx';


const mapStateToProps = (state) => {
    const {
        command,
        fetchingCommand,
    } = state.commands.toJS();

    return {
        command,
        fetchingCommand,
    };
};

export default connect(mapStateToProps)(Thanks);
