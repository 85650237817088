import Request from 'libs/request';

export function get(id) {
    return Request
        .get(`/api/v0/commands/${id}/`)
        .then(result => JSON.parse(result.text));
}

export function fetch(page, filters={}) {
    const keys = Object.keys(filters);
    const parsed_filters = keys.map((key) => `&${key}=${filters[key]}`);
    return Request
        .get(`/api/v0/commands/?page=${page}${parsed_filters}`)
        .then(result => JSON.parse(result.text));
}

export function update(id, options={}) {
    return Request
        .patch(`/api/v0/commands/${id}/`)
        .send(options)
        .then(result => JSON.parse(result.text));
}
