import { logout } from 'modules/auth/AuthActions';

const UNAUTHORIZED_STATUS = 401;

export const errorStatusMiddleware = (store) => (next) => (action) => {
    if (action.error && action.error.status) {
        switch(action.error.status) {
            case UNAUTHORIZED_STATUS:
                store.dispatch(logout());
                break;

            default:
                break;
        }
    }

    return next(action);
};