import { Map } from 'immutable';

import { MAINTENANCE } from './MaintenanceConstants';

const defaultState = () => Map({
    id: -1,
    loading: false,
    description: '',
    is_active: false,
});

export default function maintenance(state = defaultState(), action) {
    switch (action.type) {
        case MAINTENANCE.FETCH_START:
            return state.set('loading', true);
        case MAINTENANCE.FETCH_SUCCESS:
            return state.merge({
                loading: false,
                id: action.maintenance.id,
                is_active: action.maintenance.is_active,
                description: action.maintenance.description,
            });
        case MAINTENANCE.EDIT_SUCCESS:
            return state.merge({
                description: action.maintenance.description,
            });
        case MAINTENANCE.ENABLE_SUCCESS:
            return state.merge({
                is_active: true,
            });
        case MAINTENANCE.DISABLE_SUCCESS:
            return state.merge({
                is_active: false,
            });

        default:
            return state;
    }
}