import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Spin } from 'antd';

import AdminProduct from './admin/AdminProduct';
import CreateProduct from './create/CreateProduct';

import './Products.scss';

class Products extends Component {
    static propTypes = {
        creating: PropTypes.bool,
        filter: PropTypes.string,
        products: PropTypes.array,
        paginator: PropTypes.object,

        addProduct: PropTypes.func,
        fetchProducts: PropTypes.func,
        updateProduct: PropTypes.func,
        deleteProduct: PropTypes.func,
        deleteImage: PropTypes.func,
        changeFilter: PropTypes.func,
        fetchingProducts: PropTypes.bool,
    };

    componentDidMount() {
        this.props.fetchProducts();
    }

    onChangeFilter = (filter) => {
        if (filter !== this.props.filter) {
            this.props.changeFilter(filter);
        }
    }

    renderFilters() {
        const { filter } = this.props;

        return (
            <div className='Products-filters'>
                <span
                    className={filter === null ? 'mod-active' : ''}
                    onClick={() => this.onChangeFilter(null)}
                >
                    TOUS
                </span>
                <span
                    className={filter === 'GRES' ? 'mod-active' : ''}
                    onClick={() => this.onChangeFilter('GRES')}
                >
                    GRÈS
                </span>
                <span
                    className={filter === 'RAKU' ? 'mod-active' : ''}
                    onClick={() => this.onChangeFilter('RAKU')}
                >
                    RAKU
                </span>
                <span
                    className={filter === 'FEU' ? 'mod-active' : ''}
                    onClick={() => this.onChangeFilter('FEU')}
                >
                    FEU
                </span>
                <span
                    className={filter === 'BLACK' ? 'mod-active' : ''}
                    onClick={() => this.onChangeFilter('BLACK')}
                >
                    BLACK
                </span>
            </div>
        )
    }

    render() {
        const {
            products,
            creating,
            fetchingProducts,

            updateProduct,
            deleteProduct,
            deleteImage,

            addProduct,
        } = this.props;

        return (
            <div className='Products'>
                <h1>Liste et Création de produits</h1>

                { this.renderFilters() }

                <Spin spinning={fetchingProducts}>
                    <div className='Products-list'>
                        <CreateProduct
                            onClick={addProduct}
                            creating={creating}
                        />
                        {
                            products.map((product) => {
                                return (
                                    <AdminProduct
                                        key={product.id}
                                        item={product}
                                        onChange={(options) => updateProduct(product.id, options)}
                                        onDelete={() => deleteProduct(product.id)}
                                        onDeleteImage={(imageName) => deleteImage(product.id, imageName)}
                                    />
                                );
                            })
                        }
                    </div>
                </Spin>

            </div>
        );
    }
}

export default Products;