import { connect } from 'react-redux';

import {
    editMaintenance,
    fetchMaintenance,
    enableMaintenance,
    disableMaintenance,
} from '../../modules/maintenance/MaintenanceActions';
import Maintenance from './Maintenance.jsx';


const mapStateToProps = (state) => {
    const maintenance = state.maintenance.toJS();
    return {
        maintenance,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchMaintenance() {
            dispatch(fetchMaintenance());
        },
        editMaintenance(id, description) {
            dispatch(editMaintenance(id, description));
        },
        enableMaintenance(id) {
            dispatch(enableMaintenance(id));
        },
        disableMaintenance(id) {
            dispatch(disableMaintenance(id));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Maintenance);