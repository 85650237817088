import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './Product.scss';

class Product extends Component {
    static propTypes = {
        item: PropTypes.object,
        linkTo: PropTypes.string,
    };

    renderSoldOut = (item) => {
        if (item.stock > 0) {
            return false;
        }
        return (
            <div className={'Product-banner'}>
                RUPTURE
            </div>
        )
    }

    renderPromo = (item) => {
        if (item.promo <= 0 || item.stock === 0) {
            return false;
        }

        return (
            <div className={'Product-banner'}>
                {`${item.promo * 100}% de réduction !`}
            </div>
        )
    }

    renderPrice = (price, promo) => {
        if (promo<= 0) {
            return <span>{price.toFixed(2)} €</span>;
        }

        return (
            <span>
                <em>{price.toFixed(2)}€</em> {(price - (promo * price)).toFixed(2)} €
            </span>
        )
    }

    render() {
        const {
            item,
            linkTo,
        } = this.props;

        return (
            <Link
                className='Product'
                onClick={this.keepPosition}
                to={linkTo}
            >
                { this.renderSoldOut(item) }
                { this.renderPromo(item) }
                <img
                    className='Product-cover'
                    src={item.images[0]}
                    alt={item.name}
                />
                <div className='Product-footer'>
                    <p>{item.name}</p>
                    <p>{ this.renderPrice(item.price, item.promo)}</p>
                </div>
            </Link>
        );
    }
}

export default Product;