import Request from 'libs/request';

export function addItem(productId, quantity=1) {
    return Request
        .post(`/api/v0/articles/`)
        .send({
            'product': productId,
            'quantity': quantity,
        })
        .then(result => JSON.parse(result.text));
}

export function addShippingFee(country) {
    return Request
        .post(`/api/v0/carts/shipping/`)
        .send({
            country,
        })
        .then(result => JSON.parse(result.text));
}

export function update(articleId, quantity) {
    return Request
        .patch(`/api/v0/articles/${articleId}/`)
        .send({
            'quantity': quantity,
        })
        .then(result => JSON.parse(result.text));
}


export function fetch() {
    return Request
        .get('/api/v0/carts/my_cart/')
        .then(result => JSON.parse(result.text));
}

export function validate(facturation, expedition) {
    return Request
        .post(`/api/v0/carts/validate/`)
        .send({
            facturation,
            expedition,
        })
        .then(result => JSON.parse(result.text));
}
