import { connect } from 'react-redux';

import {
    shippingFee,
    validateCart,
} from '../../../modules/cart/CartActions';

import Information from './Information.jsx';


const mapStateToProps = (state) => {
    const {
        cart,
        fetchingCart,
    } = state.cart.toJS();
    const {
        errorValidation,
        validatingPayment,
    } = state.payment.toJS();

    return {
        cart,
        fetchingCart,
        errorValidation,
        validatingPayment,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        validateCart(facturation, expedition, onSuccessCallback) {
            dispatch(validateCart(facturation, expedition, onSuccessCallback));
        },
        shippingFee(country) {
            dispatch(shippingFee(country));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Information);