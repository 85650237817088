import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Spin } from 'antd';

import Product from '../../components/products/product/Product';

import './Shop.scss';


class Shop extends Component {
    static propTypes = {
        filter: PropTypes.string,
        products: PropTypes.object,
        scrollPosition: PropTypes.number,
        fetchingProducts: PropTypes.bool,

        addToCart: PropTypes.func,
        fetchProducts: PropTypes.func,
        filterByType: PropTypes.func,
        keepScroll: PropTypes.func,
    };

    componentDidMount() {
        this.props.fetchProducts();
        if (Object.keys(this.props.products).length > 0) {
            window.scrollTo({ top: this.props.scrollPosition, })
        }
    }

    componentWillUnmount() {
        this.props.keepScroll(window.pageYOffset)
    }

    onChangeFilter = (filter) => {
        if (filter !== this.props.filter) {
            this.props.filterByType(filter);
        }
    }

    renderFilters() {
        const { filter } = this.props;
        return (
            <div className='Shop-filters'>
                <span
                    className={filter === null ? 'mod-active' : ''}
                    onClick={() => this.onChangeFilter(null)}
                >
                    TOUS
                </span>
                <span
                    className={filter === 'GRES' ? 'mod-active' : ''}
                    onClick={() => this.onChangeFilter('GRES')}
                >
                    GRÈS
                </span>
                <span
                    className={filter === 'RAKU' ? 'mod-active' : ''}
                    onClick={() => this.onChangeFilter('RAKU')}
                >
                    RAKU
                </span>
                <span
                    className={filter === 'FEU' ? 'mod-active' : ''}
                    onClick={() => this.onChangeFilter('FEU')}
                >
                    FEU
                </span>
                <span
                    className={filter === 'BLACK' ? 'mod-active' : ''}
                    onClick={() => this.onChangeFilter('BLACK')}
                >
                    BLACK
                </span>
            </div>
        )
    }

    render() {
        const {
            products,
            fetchingProducts,
        } = this.props;

        return (
            <div className='Shop'>
                { this.renderFilters() }
                <Spin spinning={fetchingProducts}>
                    <div className='Shop-market'>
                        {
                            Object.values(products).reverse().map((product, key) => {
                                return (
                                    <div
                                        key={key}
                                        className='Shop-market-product'
                                    >
                                        <Product
                                            item={product}
                                            linkTo={`/product/${product.id}`}
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                </Spin>
            </div>
        );
    }
}

export default Shop;