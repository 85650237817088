import { Map } from 'immutable';

import { UI as UIContants } from './UIContants';


const defaultState = () => Map({
    scrollPosition: 0,
});

export default function UI(state = defaultState(), action) {
    switch (action.type) {
        case UIContants.SAVE_POSITION:
            return state.set('scrollPosition', action.value);

        default:
            return state;
    }
}