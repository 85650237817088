export const PRODUCTS = {
    FETCH_START: 'PRODUCTS_FETCH_START',
    FETCH_SUCCESS: 'PRODUCTS_FETCH_SUCCESS',
    FETCH_ERROR: 'PRODUCTS_FETCH_ERROR',

    FETCH_ONE_START: 'PRODUCTS_FETCH_ONE_START',
    FETCH_ONE_SUCCESS: 'PRODUCTS_FETCH_ONE_SUCCESS',
    FETCH_ONE_ERROR: 'PRODUCTS_FETCH_ONE_ERROR',

    ADD_START: 'PRODUCTS_ADD_START',
    ADD_SUCCESS: 'PRODUCTS_ADD_SUCCESS',
    ADD_ERROR: 'PRODUCTS_ADD_ERROR',

    UPDATE_START: 'PRODUCTS_UPDATE_START',
    UPDATE_SUCCESS: 'PRODUCTS_UPDATE_SUCCESS',
    UPDATE_ERROR: 'PRODUCTS_UPDATE_ERROR',

    DELETE_START: 'PRODUCTS_DELETE_START',
    DELETE_SUCCESS: 'PRODUCTS_DELETE_SUCCESS',
    DELETE_ERROR: 'PRODUCTS_DELETE_ERROR',

    CHANGE_TYPE: 'PRODUCTS_CHANGE_TYPE',
};