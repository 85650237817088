import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Address.scss';

class Address extends Component {
    static propTypes = {
        name: PropTypes.string,
        phone: PropTypes.string,
        address: PropTypes.string,
        city: PropTypes.string,
        country: PropTypes.string,
        email: PropTypes.string,
    };

    render() {
        const {
            name,
            phone,
            address,
            city,
            country,
            email,
        } = this.props;

        return (
            <div className='Address'>
                <p>{name}</p><br/>
                <p>{phone}</p>
                <p>{address}</p>
                <p>{city}</p>
                <p>{country}</p><br/>
                <p>{email ? 'Email ' : false}{email}</p>
            </div>
        );
    }
}
export default Address;