import Request from 'libs/request';

export function enable(id) {
    return Request
        .post(`/api/v0/maintenances/`)
        .send()
        .then(result => JSON.parse(result.text));
}

export function edit(id, description) {
    return Request
        .patch(`/api/v0/maintenances/${id}/`)
        .send({ description })
        .then(result => JSON.parse(result.text));
}

export function disable(id) {
    return Request
        .delete(`/api/v0/maintenances/${id}/`)
        .send()
        .then(result => JSON.parse(result.text));
}

export function fetch() {
    return Request
        .get(`/api/v0/maintenances/`)
        .send()
        .then(result => JSON.parse(result.text));
}
