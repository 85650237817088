import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Cookie from 'universal-cookie';

import { Switch, Card, Upload, Button, Form } from 'antd';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';

import Input from '../../input/Input';

import './AdminProduct.scss';


class AdminProduct extends Component {
    static propTypes = {
        item: PropTypes.object,
        onChange: PropTypes.func,
        onDelete: PropTypes.func,
        onDeleteImage: PropTypes.func,
    };

    onRemove = (file) => {
        this.props.onDeleteImage(file.url)
    }

    render() {
        const {
            item,
            onChange,
            onDelete,
        } = this.props;

        const fileList = item.images.map((image, key) => {
            return {
                uid: key,
                name: `image-${key + 1}`,
                status: 'done',
                url: image,
                thumbUrl: image,
            }
        });

        return (
            <div className='AdminProduct'>
                <Form>
                    <Card
                        cover={
                            <div>
                                <Upload
                                    action={`/api/v0/products/${item.id}/add_image/`}
                                    headers={{ 'X-CSRFToken': new Cookie().get('csrftoken') }}
                                    listType='picture'
                                    name='image_file'
                                    defaultFileList={[...fileList]}
                                    className='upload-list-inline'
                                    onRemove={this.onRemove}
                                    multiple
                                >
                                    <Button icon={<UploadOutlined />} />
                                </Upload>
                            </div>
                        }
                        actions={[
                            <div>
                                <label htmlFor='price' className='AdminProduct-label'>Prix</label>
                                <Input
                                    id='price'
                                    className='AdminProduct-money'
                                    type='number'
                                    value={item.price}
                                    onChange={(value) => onChange({'price': value})}
                                />
                            </div>,

                            <div>
                                <label htmlFor='stock' className='AdminProduct-label'>Stock</label>
                                <Input
                                    id='stock'
                                    type='number'
                                    value={item.stock}
                                    onChange={(value) => onChange({'stock': value})}
                                />
                            </div>,

                            <div>
                                <label htmlFor='promo' className='AdminProduct-label'>Promotion</label>
                                <span className='AdminProduct-promo'>
                                    <Input
                                        id='promo'
                                        type='number'
                                        value={item.promo * 100}
                                        onChange={(value) => onChange({'ratio': value / 100})}
                                    />
                                </span>
                            </div>,

                            <div>
                                <label htmlFor='delete' className='AdminProduct-label'>Supprimer</label>
                                <DeleteOutlined
                                    id='delete'
                                    onClick={onDelete}
                                    key='delete'
                                />
                            </div>,


                            <div className='AdminProduct-badge'>
                            <label htmlFor='visibility' className='AdminProduct-label'>Visible</label>
                                <Switch
                                    id='visibility'
                                    size='small'
                                    defaultChecked={item.visibility}
                                    onChange={(value) => {
                                        return onChange({'visibility': value})}}
                                />
                            </div>
                        ]}
                    >

                    <div className='CreateProduct-name'>
                            <Input
                                value={item.name}
                                onBlur={(value) => onChange({'name': value})}
                                required
                            />
                            <select
                                onChange={(event) => onChange({'type': event.target.value})}
                                value={item.type}
                            >
                                <option value='GRES'>GRÈS</option>
                                <option value='RAKU'>RAKU</option>
                                <option value='FEU'>GRÈS FEU</option>
                                <option value='BLACK'>GRÈS BLACK</option>
                            </select>
                        </div>
                        <Input
                            type='textarea'
                            className='CreateProduct-textarea'
                            value={item.description}
                            onBlur={(value) => onChange({'description': value})}
                            required
                        />

                    </Card>
                </Form>
            </div>
        );
    }
}

export default AdminProduct;