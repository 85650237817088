import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
    Form,
    Input as AntInput,
} from 'antd';

import './Input.scss';

const rules = {
    'email': [{
        message: `Saisissez votre email`,
        required: true,
        type: 'email',
    }],
    'text': [{
        required: true,
        type: 'string',
    }],
}

class Input extends Component {
    static propTypes = {
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        placeholder: PropTypes.string,
        className: PropTypes.string,
        type: PropTypes.string,
        required: PropTypes.bool,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    };

    static defaultProps = {
        onChange: () => {},
        onBlur: () => {},
        className: '',
        type: 'text',
    }

    onChange = (event) => {
        this.props.onChange(event.target.value)
    }
    onBlur = (event) => {
        this.props.onBlur(event.target.value)
    }


    renderInput() {
        const {
            type,
            required,
            className,
            placeholder,
        } = this.props;

        return (
            <div className='Input-content'>
                <Form.Item
                    rules={rules[type]}
                    name={type}
                >
                    <span className={`Input ${className}`}>
                        <AntInput
                            defaultValue={this.props.value}
                            type={type}
                            required={required}
                            placeholder={placeholder}
                            onChange={this.onChange}
                            onBlur={this.onBlur}
                        />
                    </span>
                </Form.Item>
            </div>
        )
    }

    renderInputNumber() {
        const {
            type,
            required,
            className,
            placeholder,
        } = this.props;

        return (
            <span className='Input'>
                <input
                    className={`${className}`}
                    defaultValue={this.props.value}
                    type={type}
                    required={required}
                    placeholder={placeholder}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                />
            </span>
        )
    }
    renderTextarea() {
        const {
            value,
            required,
            className,
            placeholder,
        } = this.props;

        return (
            <div className='Input'>
                <textarea
                    className={`${className}`}
                    placeholder={placeholder}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    required={required}
                    defaultValue={value}
                    cols='34'
                />
            </div>
        )
    }


    render() {
        const { type } = this.props;
        switch (type) {
            case 'textarea':
                return this.renderTextarea();
            case 'number':
                return this.renderInputNumber();;
            default:
                return this.renderInput();
        }
    }
}

export default Input;