import { Map, List } from 'immutable';

import { COMMANDS } from './CommandsConstants';

const defaultPaginator = () => Map({
    total_elements: 0,
    page_size: 200,
    current: 1,
});

const defaultState = () => Map({
    commands: List([]),
    paginator: defaultPaginator(),
    command: Map({}),
    fetchingCommands: false,
    fetchingCommand: false,
});

export default function commands(state = defaultState(), action) {
    switch (action.type) {

        case COMMANDS.GET_COMMAND_START:
            return state.merge({
                command: Map({}),
                fetchingCommand: true,
            });

        case COMMANDS.GET_COMMAND_SUCCESS:
            return state.merge({
                command: action.command,
                fetchingCommand: false,
            });

        case COMMANDS.GET_COMMAND_ERROR:
            return state.merge({
                fetchingCommand: false,
            });

        case COMMANDS.FETCH_START:
            return state.merge({
                fetchingCommands: true,
            });

        case COMMANDS.FETCH_SUCCESS:
            return state.merge({
                commands: List([ ...action.commands ]),
                paginator: Map({ ...action.paginator }),
                fetchingCommands: false,
            });

        case COMMANDS.FETCH_ERROR:
            return state.merge({
                commands: List([]),
                paginator: defaultPaginator(),
                fetchingCommands: false,
            });


        case COMMANDS.UPDATE_SUCCESS:
            const commands = state.get('commands').toArray()
            for (let index = 0; index < commands.length; index++) {
                if (commands[index]['id'] === action.command['id']) {
                    return state.setIn(['commands', index], action.command)
                }
            }
            return state

        case COMMANDS.UPDATE_START:
        case COMMANDS.UPDATE_ERROR:
            return state;

        default:
            return state;
    }
}