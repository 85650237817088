import { connect } from 'react-redux';

import {
    updateCart,
} from '../../../modules/cart/CartActions';

import Validation from './Validation.jsx';


const mapStateToProps = (state) => {
    const {
        cart,
    } = state.cart.toJS();
    return {
        cart,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateCart(articleId, quantity) {
            dispatch(updateCart(articleId, quantity));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Validation);