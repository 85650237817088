import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import { InstagramOutlined } from '@ant-design/icons';
import { Config } from '../../config'

import './Footer.scss';

class Footer extends PureComponent {

    render() {
        if (window.location.pathname === '/store') {
            return false;
        }

        return (
            <div className='Footer'>
                <div className='Footer-ads'>
                    <a
                        href={Config.INSTAGRAM}
                        target='_blank'
                        rel='noreferrer noopener'
                    >
                        <InstagramOutlined />
                    </a>
                </div>
                <div className='Footer-copyright'>
                    <p>Tous droits réservés</p>
                    <p>© 2021 | <a rel='noreferrer noopener' target='_blank' href={`mailto:${Config.MAIL}`}>Contact</a> | <Link to='/policy'>Conditions générales d'utilisation</Link></p>
                </div>
            </div>
        );
    }
}

export default Footer;