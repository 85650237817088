import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './Home.scss';

import feu from './feu.jpg';
import gres from './gres.jpg';
import raku from './raku.jpg';
import black from './black.jpg';

class Home extends PureComponent {
    static propTypes = {
        navTo: PropTypes.func,
        maintenance: PropTypes.object,
    };

    render() {
        const { navTo, maintenance } = this.props;
        return (
            <div className='Home'>
                <p className='Home-text'>
                    {
                        maintenance.is_active ?
                            '' : 'Vous surprendre, vous séduire et vous proposer de belles choses aimables pour embellir la vie et vos interieurs.'
                    }
                </p>

                <div className='Home-sections'>
                    <div onClick={() => navTo('GRES')}>
                        <img src={gres} alt='Grès' />
                        <p>PIÈCES GRÈS</p>
                    </div>

                    <div onClick={() => navTo('RAKU')}>
                        <img src={raku} alt='Raku' />
                        <p>PIÈCES RAKU</p>
                    </div>

                    <div onClick={() => navTo('FEU')}>
                        <img src={feu} alt='Grès Feu' />
                        <p>PIÈCES FEU</p>
                    </div>

                    <div onClick={() => navTo('BLACK')}>
                        <img src={black} alt='Grès Black' />
                        <p>PIÈCES BLACK</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;