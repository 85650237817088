import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import {
    filterByType,
} from '../../modules/products/ProductsActions';

import Home from './Home.jsx';

const mapStateToProps = (state) => {
    const maintenance = state.maintenance.toJS();
    return {
        maintenance,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        navTo(type) {
            dispatch(filterByType(type));
            dispatch(push('/shop'));
        },

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
