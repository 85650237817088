import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Table } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { Config } from 'config.js';

import './Cart.scss';


class Cart extends Component {
    static propTypes = {
        cart: PropTypes.object,
        shipping: PropTypes.bool,
        mini: PropTypes.bool,
        taxe: PropTypes.bool,
        freeze: PropTypes.bool,
        hideHeader: PropTypes.bool,

        updateCart:  PropTypes.func,
    };

    renderItem(article) {
        const cx = this.props.mini ? 'mod-mini' : '';
        return (
            <div className={`Cart-item ${cx}`}>
                {
                    article.images.length > 0 ?
                        <img
                            className='Cart-item-thumbnail'
                            src={article.images[0]}
                            alt={article.name}
                        /> : false
                }
                <p className='Cart-item-name'>
                    {article.name}
                </p>
            </div>
        )
    }

    renderRemove = (article) => {
        return (
            <button
                onClick={() => {
                    return this.props.updateCart(article.article_id, 0)
                }}
            >
                <DeleteOutlined />
            </button>
        )
    }

    renderQuantity(article) {
        const options = []
        for (let index = 0; index <= article.stock; index++) {
            options.push(
                <option
                    key={index}
                    value={index}
                >
                    {index}
                </option>
            );
        }

        return (
            <select
                disabled={this.props.freeze}
                value={article.quantity}
                onChange={(event) => this.props.updateCart(article.article_id, event.target.value)}
            >
                { options }
            </select>
        )
    }

    getColumns = () => {
        return [{
            title: '',
            render: (_, article) => this.renderRemove(article)
        },{
            title: 'Produit',
            render: (_, article) => this.renderItem(article)
        },
        {
            title: 'Quantité',
            render: (_, article) => this.renderQuantity(article)
        },
        {
            title: `${this.props.taxe ? ' HT' : 'Prix'}`,
            render: (_, article) => {
                let promo = article.price;
                if (article.promo > 0) {
                    promo = (article.price - (article.price * article.promo));
                }

                // Taxe
                const unpromoPrice = this.props.taxe ? (article.price / ((Config.TAXE / 100) + 1)) : article.price;
                const promoPrice = this.props.taxe ? (promo / ((Config.TAXE / 100) + 1)) : promo;

                if (article.promo > 0) {
                    return (
                        <p>
                            <em>{unpromoPrice.toFixed(2)}€</em>&nbsp;
                            {promoPrice.toFixed(2)}€&nbsp;
                            ({(article.promo * 100 * -1)}%)
                        </p>
                    )
                }

                return (
                    <p>{unpromoPrice.toFixed(2)} €</p>
                );
            }
        },{
            title: 'TVA',
            render: (_, article) => {
                if (!this.props.taxe) {
                    return false;
                }
                let price = article.price;
                if (article.promo > 0) {
                    price = (price - (price * article.promo));
                }

                return (
                    <p>{(price - (price / ((Config.TAXE / 100) + 1))).toFixed(2)} €</p>
                );
            }
        }];
    }

    render() {
        const {
            cart,
            mini,
            taxe,
            hideHeader,
            shipping,
        } = this.props;

        const columns = this.getColumns();
        if (mini) {
            const index = columns.findIndex(c => c.title === '')
            columns.splice(index, 1)
        }
        if (!taxe) {
            const index = columns.findIndex(c => c.title === 'TVA')
            columns.splice(index, 1)
        }

        let articles = cart.articles;
        if (shipping) {
            const { country, price } = cart.shipping_fee;
            if (articles.findIndex(article => article.id === `shipping-${cart.id}`) === -1) {
                articles.push({
                    'id': `shipping-${cart.id}`,
                    'images': [],
                    'name': `Frai de port ${country}`,
                    'price': price,
                    'quantity': 1,
                    'stock': 1,
                });
            }
        }

        return (
            <div className='Cart'>
                <Table
                    columns={columns}
                    dataSource={articles}
                    pagination={false}
                    showHeader={!hideHeader}
                    rowKey={'id'}
                />

            </div>
        );
    }
}

export default Cart;