import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Layout, Menu, Button } from 'antd';
import {
    SkinOutlined,
    WarningOutlined,
    ShoppingCartOutlined,
} from '@ant-design/icons';

import Login from '../login/LoginContainer';
import Products from '../../components/products/ProductsContainer';
import Commands from '../../components/commands/CommandsContainer';
import Maintenance from '../../components/maintenance/MaintenanceContainer';

import './Store.scss';

const { Content, Sider } = Layout;

const MENU = ['Produits', 'Commandes', 'Maintenance'];

class Store extends Component {
    static propTypes = {
        loginStateReadFromServer: PropTypes.bool,
        loginStateProcessing: PropTypes.bool,
        myAccount: PropTypes.object,
        pathname: PropTypes.string,
        children: PropTypes.node,

        checkLoginState: PropTypes.func,
        navigateTo: PropTypes.func,
        logout: PropTypes.func,
    };

    state = {
        collapsed: false,
        selectedKey: 0,
    };

    componentDidMount() {
        if (this.props.myAccount.is_authenticated) {
            this.props.checkLoginState();
        }
        const index = MENU.findIndex(item => `#${item.toLowerCase()}` === window.location.hash.toLowerCase());
        this.setState({
            selectedKey: index > 0 ? index : 0,
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.loginStateReadFromServer && !prevProps.loginStateReadFromServer) {
            this.setState({ collapsed: false });
        }
    }


    onCollapse = collapsed => {
        this.setState({ collapsed });
    };

    onClick = (item) => {
        window.location.hash = MENU[item].toLowerCase();
        this.setState({selectedKey: item});
    }

    renderContent(key) {
        switch (key) {
            case 0:
                return <Products />
            case 1:
                return <Commands />
            case 2:
            default:
                return <Maintenance />
        }
    }

    renderIcon(item) {
        switch (item) {
            case 'Commandes':
                return <ShoppingCartOutlined />;
            case 'Produits':
                return <SkinOutlined />;
            default:
                return <WarningOutlined />;
        }
    }

    renderShop () {
        const { logout } = this.props;
        const {
            collapsed,
            selectedKey,
        } = this.state;

        return (
            <div className='Store'>
                <Layout style={{ minHeight: '100vh' }}>
                    <Sider
                        collapsible
                        collapsed={collapsed}
                        onCollapse={this.onCollapse}
                    >
                        <Menu
                            theme='dark'
                            mode='inline'
                            selectedKeys={`${selectedKey}`}
                        >
                        {
                            MENU.map((item, key) => {
                                return (
                                    <Menu.Item
                                        key={key}
                                        onClick={() => this.onClick(key)}
                                        icon={this.renderIcon(item)}
                                    >
                                        {item}
                                    </Menu.Item>
                                )
                            })
                        }
                        </Menu>

                        <Button onClick={logout}>Logout</Button>
                    </Sider>

                    <Layout className='Store-layout'>
                        <Content style={{ margin: '0 16px' }}>
                            {this.renderContent(selectedKey)}
                        </Content>
                    </Layout>
                </Layout>
            </div>
        );
    }

    render() {
        const {
            loginStateReadFromServer,
        } = this.props;

        if (!loginStateReadFromServer) {
            return <Login />;
        }

        return this.renderShop();
    }

}

export default Store;