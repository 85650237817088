import Request from 'libs/request';

export function postLogin(payload) {
    return Request
        .post('/api/login')
        .send(payload)
        .then(result => JSON.parse(result.text));
}

export function postLogout() {
    return Request
        .post('/api/logout')
        .send();
}

export function getCheck() {
    return Request
        .get('/api/check')
        .send();
}