export const PAYMENT = {
    VALIDATE_CART_START: 'PAYMENT_VALIDATE_CART_START',
    VALIDATE_CART_SUCCESS: 'PAYMENT_VALIDATE_CART_SUCCESS',
    VALIDATE_CART_ERROR: 'PAYMENT_VALIDATE_CART_ERROR',

    PURCHASE_START: 'PAYMENT_PURCHASE_START',
    PURCHASE_SUCCESS: 'PAYMENT_PAYMENT_SUCCESS',
    PURCHASE_ERROR: 'PAYMENT_PURCHASE_ERROR',

    PURCHASE_CLEAN: 'PAYMENT_PURCHASE_CLEAN',
};