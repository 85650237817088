import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Address from '../../../components/address/Address';
import Cart from '../../../components/cart/Cart';

import { Config } from '../../../config'

import './Thanks.scss';

const INVOICE_URL = '/api/v0/commands/__COMMAND_ID__/generate_invoice/';

class Thanks extends Component {
    static propTypes = {
        command: PropTypes.object,
        fetchingCommand: PropTypes.bool,
    };

    renderAddress() {
        const {
            command,
        } = this.props;

        return (
            <div className='Commands-modal-addresses'>
                <div>
                    <h2>Facturation</h2>
                    <Address
                        email={command.facturation.email}
                        name={command.facturation.name}
                        phone={command.facturation.phone}
                        address={command.facturation.address}
                        country={command.facturation.country}
                        city={command.facturation.city}
                    />
                </div>

                <div>
                    <h2>Expédition</h2>
                    <Address
                        name={command.expedition.name}
                        phone={command.expedition.phone}
                        address={command.expedition.address}
                        country={command.expedition.country}
                        city={command.expedition.city}
                    />
                </div>
            </div>
        )
    }

    renderMap() {
        const {
            command,
        } = this.props;

        const address = encodeURIComponent(`${command.expedition.address}, ${command.expedition.city}, ${command.expedition.country}`)

        return (
            <div className='Thanks-content-map'>
                <iframe
                    title='address'
                    width='100%'
                    height='100%'
                    src={`https://www.google.com/maps/embed/v1/search?q=${address}&key=${Config.API_KEY_MAP}`}
                />
            </div>
        )
    }

    renderContent() {
        const {
            command,
        } = this.props;

        return (
            <div className='Thanks-content'>
                <div>
                    <h1>Merci {command.facturation.name} !</h1>
                    <a
                        href={INVOICE_URL.replace('__COMMAND_ID__', command.id)}
                        className='Thanks-button'
                    >
                        Télécharger votre facture
                    </a>

                </div>

                { /* this.renderMap() */ }

                { this.renderAddress() }

                <p className='Thanks-contact'>Besoin d'aide ? <a rel='noreferrer noopener' target='_blank' href={`mailto:${Config.MAIL}`}>Nous contacter</a></p>
            </div>
        )
    }

    renderCart() {
        const {
            command,
        } = this.props;

        return (
            <div className='Thanks-cart'>
                <Cart
                    cart={command}
                    shipping
                    mini
                    freeze
                    taxe
                />
                <p className='Commands-modal-total'>
                    Total TTC {(command.total + command.shipping_fee.price || 0).toFixed(2)} €
                </p>
            </div>
        )
    }

    render() {
        const {
            fetchingCommand,
            command,
        } = this.props;
        const isLoaded = !fetchingCommand && Object.keys(command).length > 0;
        return (
            <div className='Thanks'>
                { isLoaded ? this.renderContent() : false }
                { isLoaded ? this.renderCart() : false }
            </div>
        );
    }
}

export default Thanks;