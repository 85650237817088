import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Spin, Form } from 'antd';

import Input from '../../../components/input/Input';
import Cart from '../../../components/cart/Cart';
import Total from '../../../components/total/Total';
import Countries from '../../../components/countries/Countries';

import { Config } from '../../../config'

import './Information.scss';

class Information extends Component {
    static propTypes = {
        cart: PropTypes.object,
        errorValidation: PropTypes.string,
        validatingPayment: PropTypes.bool,

        nextStep: PropTypes.func,
        shippingFee: PropTypes.func,
        validateCart: PropTypes.func,
    };

    state = {
        userFacturation: {},
        userExpedition: {},
        step: 0,
        isSameAddress: true,
    }

    componentDidMount() {
        this.onChange('country', 'France', false);
        this.onChange('country', 'France', true);
        this.props.shippingFee('France');
    }

    onChange = (key, value, isFacturation, callback) => {
        const {
            userFacturation,
            userExpedition,
        } = this.state;
        isFacturation ? userFacturation[key] = value : userExpedition[key] = value;

        this.setState({
            userFacturation,
            userExpedition,
        }, callback);
    }

    onSubmitInformation = (callback) => {
        const {
            userFacturation,
            userExpedition,
            isSameAddress
        } = this.state;

        let facturation = userFacturation;
        if (isSameAddress) {
            console.log(userExpedition);
            facturation = { ...userExpedition };
        }

        const { validateCart } = this.props;

        return validateCart(
            {
                email: userFacturation.email,
                phone: facturation.phone,
                city: `${facturation.zip} ${facturation.city}`,
                country:facturation.country,
                address: facturation.address,
                name: `${facturation.firstname} ${facturation.lastname}`,
            },
            {
                city: `${userExpedition.zip} ${userExpedition.city}`,
                phone: userExpedition.phone,
                country:userExpedition.country,
                address: userExpedition.address,
                name: `${userExpedition.firstname} ${userExpedition.lastname}`,
            }, callback);
    }

    onSubmit = (step) => {
        const { isSameAddress } = this.state;
        const { nextStep } = this.props;

        if (step === 1) {
            return this.setState({ step });
        }
        if (step === 2) {
            if (isSameAddress) {
                return this.onSubmitInformation(nextStep);
            }
            return this.setState({ step });
        }
        if (step === 3) {
            return this.onSubmitInformation(nextStep);
        }
    }

    renderEmail() {
        return (
            <Form
                initialValues={{ remember: true }}
                onFinish={() => this.onSubmit(1)}
                className='Information-user-email'
            >
                <h3>1. Votre email</h3>
                <Input
                    placeholder='Email'
                    type='email'
                    onChange={(value) => this.onChange('email', value, true)}
                    required
                />
                <p className='Information-user-text'>Pour recevoir votre facture</p>
                <div>
                    <input
                        className='Information-submit'
                        type='submit'
                        value='Valider'
                    />
                </div>
            </Form>
        )
    }

    renderShipping() {
        return (
            <Form
                initialValues={{ remember: true }}
                onFinish={() => this.onSubmit(2)}
                className='Information-user-address'
            >
                <h3>2. Adresse d'expédition</h3>
                <div className='Information-name'>
                    <Input
                        key='shipping-name'
                        placeholder='Nom*'
                        onChange={(value) => this.onChange('lastname', value)}
                        required
                    />
                    <Input
                        key='shipping-lastname'
                        placeholder='Prénom*'
                        onChange={(value) => this.onChange('firstname', value)}
                        required
                    />
                </div>

                <Input
                    key='shipping-phone'
                    placeholder='Téléphone'
                    type='tel'
                    onChange={(value) => this.onChange('phone', value)}
                    required
                />

                <Input
                    key='shipping-street'
                    placeholder='Numéro et rue*'
                    onChange={(value) => this.onChange('address', value)}
                    required
                />

                <div>
                    <Input
                        key='shipping-zip'
                        placeholder='Code postal*'
                        onChange={(value) => this.onChange('zip', value)}
                        required
                    />
                    <Input
                        key='shipping-city'
                        placeholder='Ville*'
                        onChange={(value) => this.onChange('city', value)}
                        required
                    />
                </div>

                <div>
                    <Countries
                        key='shipping-country'
                        disabled
                        onChange={(value) => {
                            this.onChange('country', value, false);
                            this.props.shippingFee(value);
                        }}
                        placeholder='Pays*'
                        required
                    />
                    <p>
                        Si vous souhaitez vous faire livrer en Outre mer ou à l'étranger, merci de prendre <a rel='noreferrer noopener' target='_blank' href={`mailto:${Config.MAIL}`}>contact</a>  avec nous.
                    </p>
                </div>

                <div>
                    <input
                        id='same_address'
                        type='checkbox'
                        onChange={(event) => this.setState({ isSameAddress: event.target.checked })}
                        defaultChecked
                    />
                    <label
                        className='Information-checkbox'
                        htmlFor='same_address'
                    >
                        L'adresse de facturation est la même
                    </label>
                </div>

                <input
                    type='submit'
                    value='Continuer'
                    className='Information-submit'
                />
                <p className='Information-error'>{this.props.errorValidation}</p>
            </Form>
        )
    }

    renderFacturation() {
        return (
            <Form
                initialValues={{ remember: true }}
                onFinish={() => this.onSubmit(3)}
                className='Information-user-address'
            >
                <h3>3. Adresse de facturation</h3>
                <div className='Information-name'>
                    <Input
                        key='facturation-name'
                        placeholder='Nom*'
                        onChange={(value) => this.onChange('lastname', value, true)}
                        required
                    />
                    <Input
                        key='facturation-lastname'
                        placeholder='Prenom*'
                        onChange={(value) => this.onChange('firstname', value, true)}
                        required
                    />
                </div>

                <Input
                    key='facturation-phone'
                    placeholder='Téléphone'
                    type='tel'
                    onChange={(value) => this.onChange('phone', value, true)}
                    required
                />

                <Input
                    key='facturation-street'
                    placeholder='Numéro et rue*'
                    onChange={(value) => this.onChange('address', value, true)}
                    required
                />

                <div>
                    <Input
                        key='facturation-zip'
                        placeholder='Code postale*'
                        onChange={(value) => this.onChange('zip', value, true)}
                        required
                    />
                    <Input
                        key='facturation-city'
                        placeholder='Ville*'
                        onChange={(value) => this.onChange('city', value, true)}
                        required
                    />
                </div>

                <Countries
                    key='facturation-country'
                    onChange={(value) => this.onChange('country', value, true)}
                    placeholder='Pays*'
                    required
                />

                <input
                    type='submit'
                    value='Continuer'
                    className='Information-submit'
                />
            </Form>
        )
    }


    renderStep() {
        switch (this.state.step) {
            case 0:
                return this.renderEmail();
            case 1:
                return this.renderShipping();
            case 2:
                default:
                return this.renderFacturation();
        }
    }


    render() {
        const {
            cart,
            validatingPayment,
        } = this.props;

        return (
            <Spin spinning={validatingPayment}>
                <div className='Information'>
                    <div className='Information-user'>
                        { this.renderStep() }
                    </div>

                    <div className='Information-cart'>
                        <h2>Commande</h2>
                        <Cart
                            mini
                            hideHeader
                            freeze
                            cart={cart}
                        />
                        <div className='Information-total'>
                            <Total
                                total={cart.total}
                                shipping={cart.shipping}
                            />
                        </div>
                    </div>
                </div>
            </Spin>
        );
    }
}
export default Information;