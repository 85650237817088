import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Carousel as AntdCarousel} from 'antd';

import './Carousel.scss';

const LEFT = 37;
const RIGHT = 39;
class Carousel extends Component {

    static propTypes = {
        name: PropTypes.string,
        images: PropTypes.array
    };

    state = {
        selected: 0,
    }

    componentDidMount() {
        window.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeyDown);
    }

    handleKeyDown = (event) => {
        if (event.keyCode === LEFT) {
            this.carousel.prev();
        } else if (event.keyCode === RIGHT) {
            this.carousel.next()
        }
    }

    navigateTo = (key) => {
        this.carousel.goTo(key);
    }

    renderThumbnails = () => {
        const {
            name,
            images,
        } = this.props;
        const { selected } = this.state;
        if (images.length === 1) {
            return false;
        }

        return (
            <div className='Carousel-thumbnails'>
                {
                    images.map((image, key) => {
                        const cx = selected === key ? 'mod-selected' : '';
                        return (
                            <img
                                key={key}
                                className={`Carousel-thumbnails-item ${cx}`}
                                src={image}
                                alt={name}
                                onClick={() => this.navigateTo(key)}
                            />
                        );
                    })
                }
            </div>
        )
    }

    render() {
        const {
            name,
            images,
        } = this.props;

        return (
            <div className='Carousel'>
                <div className='Carousel-content'>
                    <AntdCarousel
                        effect='fade'
                        dots={false}
                        ref={(carousel) => this.carousel = carousel}
                        beforeChange={(_, to) => this.setState({ selected: to })}
                    >
                        {
                            images.map((image, key) => {
                                return (
                                    <img
                                        key={key}
                                        className='Carousel-content-image'
                                        src={image}
                                        alt={name}
                                    />
                                );
                            })
                        }
                    </AntdCarousel>
                </div>
                { this.renderThumbnails() }
            </div>
        );
    }
}
export default Carousel;