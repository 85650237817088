import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '../../components/button/Button';
import Carousel from '../../components/carousel/Carousel';

import './DetailProduct.scss';

import logo1 from './logo-1.jpg';
import logo2 from './logo-2.jpg';
import logo3 from './logo-3.jpg';

const CONTENT = {
    'RAKU': `
Cette pièce est unique et façonnée à la main.
Après une période de séchage et une première cuisson, la terre est émaillée puis cuite une deuxième fois au gaz.
C’est pendant cette deuxième cuisson que la pièce en fusion est sortie du four à 980° puis enfumée dans de la sciure de bois de différentes essences,
donnant ainsi différents effets à l’émail.
Cette technique de cuisson laisse le grés quelque peu poreux.
Aussi, nous vous conseillons si vous voulez y mettre des fleurs fraîches par exemple, de placer auparavant un récipient en verre à l’interieur afin de protéger vos meubles Louis XV.
`,
    'GRES': `
Cette pièce est unique et façonnée à la main. \
Après une période de séchage et une première cuisson, la terre est émaillée puis cuite une deuxième fois.
Cette technique de cuisson referme totalement la terre et l’émail lui assure une étanchéité parfaite.
`,
    'FEU': `
Cette pièce est unique et façonnée à la main.
Après une période de séchage, de polissage et une première cuisson, la terre est baignée dans une préparation à base d’oxydes puis cuite une deuxième fois au gaz.
C'est lors de cette deuxième cuisson allant jusqu'à 750° puis grâce à son refroidissement que la magie opère et qu'apparaîssent les couleurs.
Cette technique de cuisson laisse le grés quelque peu poreux.
Aussi, nous vous conseillons si vous voulez y mettre des fleurs fraîches par exemple, de placer auparavant un récipient en verre à l’interieur afin de protéger vos meubles Louis XV.
`,
    'BLACK': `
Cette pièce est unique et façonnée à la main.
Après une période de séchage et une première cuisson, elle est cuite une deuxième fois au gaz.
C’est pendant cette deuxième cuisson que la pièce en fusion est sortie du four à 980° puis enfumée et étoufée dans de la sciure de bois.
Cette technique de cuisson laisse le grés quelque peu poreux. Aussi, nous vous conseillons si vous voulez y mettre des fleurs fraîches par exemple (vase), de placer auparavant un récipient en verre à l’interieur afin de protéger vos meubles Louis XV.
`,
}

class DetailProduct extends Component {
    static propTypes = {
        cart: PropTypes.object,
        product: PropTypes.object,
        productId: PropTypes.number,
        adding: PropTypes.bool,
        fetchingProduct: PropTypes.bool,

        addToCart: PropTypes.func,
        fetchProduct: PropTypes.func,
    };

    state = {
        quantity: 1,
    }

    componentDidMount() {
        const {
            product,
            productId,
            fetchProduct,
        } = this.props;
        if (
            Object.keys(product).length === 0
            || product.id !== productId
        ) {
            fetchProduct(productId);
        }
    }


    renderSlider() {
        const {
            product,
        } = this.props;
        return (
            <div className='DetailProduct-images'>
                <Carousel
                    images={product.images}
                    name={product.name}
                />
            </div>
        )
    }

    renderPromoOrStock(item) {
        if (item.stock <= 0) {
            return (
                <div className={'DetailProduct-desc-banner'}>
                    RUPTURE
                </div>
            );
        }

        if (item.promo <= 0) {
            return false;
        }

        return (
            <div className={'DetailProduct-desc-banner'}>
                {`${item.promo * 100}% de réduction !`}
            </div>
        )
    }

    renderPrice = (price, promo) => {
        if (promo <= 0) {
            return <span>{price.toFixed(2)} €</span>;
        }

        return (
            <span>
                <em>{price.toFixed(2)}€</em> {(price - (promo * price)).toFixed(2)} €
            </span>
        )
    }

    renderDescriptif () {
        const { product } = this.props;
        const content = CONTENT[product.type];

        return (
            <div className='DetailProduct-desc-story'>
                {content}
            </div>
        )
    }

    renderLogos () {
        const {
            product,
        } = this.props;

        return (
            <div className='DetailProduct-desc-logos'>
                <img src={logo1} alt='Fait main'/>
                <img src={logo2} alt='Piece unique'/>
                {
                    product.type !== 'GRES' ?
                        <img src={logo3} alt={'Ne pas utiliser pour l\'alimentaire'} />
                        : false
                }
            </div>
        )
    }

    renderDesc() {
        const {
            product,
        } = this.props;
        const {
            quantity,
        } = this.state;

        return (
            <div className='DetailProduct-desc'>
                <div>
                    <div>
                        <h2>{product.name}</h2>
                        { this.renderPromoOrStock(product) }
                        <p className='DetailProduct-desc-price'>{this.renderPrice(product.price, product.promo)}</p>
                    </div>

                    <p className='DetailProduct-desc-text'>{product.description}</p>
                </div>

                <div>
                    {this.renderDescriptif()}
                    {this.renderLogos()}

                    <div className='DetailProduct-desc-purchase'>
                        <div className='DetailProduct-desc-purchase-quantity'>
                            <label htmlFor='quantity'>Quantité</label>
                            <input
                                type='number'
                                max={product.stock}
                                id='quantity'
                                min={1}
                                value={quantity}
                                onChange={(event) => this.setState({quantity: event.target.value})}
                            />
                        </div>
                        { this.renderAddToCart() }
                    </div>
                </div>
            </div>
        )
    }

    renderAddToCart = () => {
        const {
            cart,
            adding,
            product,
            addToCart,
        } = this.props;
        const { quantity } = this.state;

        let alreadyInCart = 0;
        if (cart.articles) {
            const article = cart.articles.find(article => article.id === product.id);
            alreadyInCart = article ? article.quantity : 0;
        }

        let text = 'Stock insuffisant';
        let disabled = true;
        if (quantity > 0 && product.stock >= Number.parseInt(quantity) + alreadyInCart) {
            text = 'Ajouter au panier';
            disabled = false;
        }
        if (adding) {
            text = 'Ajouter au panier ...';
            disabled = true;
        }

        return (
            <Button
                disabled={disabled}
                onClick={() => addToCart(product.id, quantity)}
            >
                {text}
            </Button>
        )
    }

    render() {
        const {
            product,
            fetchingProduct,
        } = this.props;

        const loading = fetchingProduct || Object.keys(product).length === 0;

        return (
            <div className='DetailProduct'>
                {loading ? false : this.renderSlider()}
                {loading ? false : this.renderDesc()}
            </div>
        );
    }
}

export default DetailProduct;