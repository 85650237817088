import { connect } from 'react-redux';

import { fetchCart } from '../../modules/cart/CartActions';
import { fetchMaintenance } from '../../modules/maintenance/MaintenanceActions';
import Header from './Header.jsx';


const mapStateToProps = (state) => {
    const {
        cart,
        fetchingCart,
    } = state.cart.toJS();
    const maintenance = state.maintenance.toJS();

    return {
        cart,
        fetchingCart,
        maintenance,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCart() {
            dispatch(fetchCart());
        },
        fetchMaintenance() {
            dispatch(fetchMaintenance());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);