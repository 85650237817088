import { connect } from 'react-redux';

import {
    filterByType,
    fetchProducts,
} from '../../modules/products/ProductsActions';
import { addToCart } from '../../modules/cart/CartActions';
import { savePosition } from '../../modules/ui/UIActions';
import Shop from './Shop.jsx';


const mapStateToProps = (state) => {
    const {
        filter,
        products,
        fetchingProducts,
    } = state.products.toJS();
    const {
        scrollPosition,
    } = state.ui.toJS();
    return {
        filter,
        products,
        scrollPosition,
        fetchingProducts,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchProducts() {
            dispatch(fetchProducts());
        },
        addToCart(productId, quantity) {
            dispatch(addToCart(productId, quantity));
        },
        filterByType(type) {
            dispatch(filterByType(type));
            dispatch(fetchProducts());
        },
        keepScroll(value) {
            dispatch(savePosition(value));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Shop);