import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { resetCommand } from '../../modules/payment/PaymentActions';
import { fetchCommand } from '../../modules/commands/CommandsActions';
import { fetchMaintenance } from '../../modules/maintenance/MaintenanceActions';

import Checkout from './Checkout.jsx';


const mapStateToProps = (state) => {
    const { command } = state.payment.toJS();
    const maintenance = state.maintenance.toJS();

    return {
        command,
        maintenance,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetCommand() {
            dispatch(resetCommand());
        },
        changeURL(uuid) {
            dispatch(push({search: `?uuid=${uuid}`}));
        },
        loadCommand(id) {
            dispatch(fetchCommand(id));
        },
        fetchMaintenance() {
            dispatch(fetchMaintenance());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
