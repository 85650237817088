import { Map } from 'immutable';

import { CART } from './CartConstants';

const defaultCart = () => Map({
    total: 0,
})

const defaultState = () => Map({
    cart: defaultCart(),
    fetchingCart: false,
    adding: false,
});

export default function cart(state = defaultState(), action) {
    switch (action.type) {
        case CART.ADD_START:
        case CART.ADD_SHIPPING_FEE_START:
            return state.merge({
                adding: true,
            });
        case CART.ADD_SUCCESS:
        case CART.ADD_SHIPPING_FEE_SUCCESS:
            return state.merge({
                cart: action.cart,
                adding: false,
            });
        case CART.ADD_ERROR:
        case CART.ADD_SHIPPING_FEE_ERROR:
            return state.merge({
                adding: false,
            });

        case CART.UPDATE_SUCCESS:
            return state.merge({
                cart: action.cart,
            });

        case CART.FETCH_START:
            return state.merge({
                fetchingCart: true,
            });

        case CART.FETCH_SUCCESS:
            return state.merge({
                cart: action.cart,
                fetchingCart: false,
            });

        case CART.FETCH_ERROR:
            return state.merge({
                cart: defaultCart(),
                fetchingCart: false,
            });

        case CART.CLEAN:
            return state.merge({
                cart: defaultCart(),
            });

        default:
            return state;
    }
}