export const COMMANDS = {
    FETCH_START: 'COMMANDS_FETCH_START',
    FETCH_SUCCESS: 'COMMANDS_FETCH_SUCCESS',
    FETCH_ERROR: 'COMMANDS_FETCH_ERROR',

    GET_COMMAND_START: 'COMMANDS_GET_COMMAND_START',
    GET_COMMAND_SUCCESS: 'COMMANDS_GET_COMMAND_SUCCESS',
    GET_COMMAND_ERROR: 'COMMANDS_GET_COMMAND_ERROR',

    UPDATE_START: 'COMMANDS_UPDATE_START',
    UPDATE_SUCCESS: 'COMMANDS_UPDATE_SUCCESS',
    UPDATE: 'COMMANDS_UPDATE_ERROR',
};