import uiReducer from 'modules/ui/UIReducer';
import authReducer from 'modules/auth/AuthReducer';
import cartReducer from 'modules/cart/CartReducer';
import paymentReducer from 'modules/payment/PaymentReducer';
import productsReducer from 'modules/products/ProductsReducer';
import commandsReducer from 'modules/commands/CommandsReducer';
import maintenanceReducer from 'modules/maintenance/MaintenanceReducer';

const reducers = {
    ui: uiReducer,
    auth: authReducer,
    cart: cartReducer,
    payment: paymentReducer,

    //Admin dashboard
    products: productsReducer,
    commands: commandsReducer,
    maintenance: maintenanceReducer,
};

export default reducers;