import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Cart from '../../../components/cart/Cart';
import Total from '../../../components/total/Total';
import Button from '../../../components/button/Button';

import './Validation.scss';


class Validation extends Component {
    static propTypes = {
        cart: PropTypes.object,
        maintenance: PropTypes.object,

        nextStep: PropTypes.func,
        updateCart:  PropTypes.func,
    };

    state = {
        userInfo: {},
    }

    render() {
        const {
            cart,
            nextStep,
            updateCart,
            maintenance,
        } = this.props;

        return (
            <div className='Validation'>
                <h2>Mon panier</h2>
                <div>
                    <Cart
                        cart={cart}
                        updateCart={updateCart}
                    />

                    <div className='Validation-total'>
                        <Total
                            onlySum
                            total={cart.total}
                        />
                    </div>

                    <div className='Validation-checkout'>
                        <Button
                            onClick={nextStep}
                            disabled={
                                !cart.articles ||
                                cart.articles.length === 0 ||
                                (maintenance.loading || maintenance.is_active)
                            }
                        >
                            Valider le panier
                        </Button>

                    </div>
                </div>
            </div>
        );
    }
}
export default Validation;