export const Config = {
    // Owner
    MAIL: 'contact@desnouvellesdelaterre.com',
    SOCIETY: 'Ici-même',
    ADDRESS: '210 rue Jean Moulin, Betton-Bettonet, 73390, France',

    // External link
    INSTAGRAM: 'https://www.instagram.com/DesNouvellesDeLaTerre',

    // Shiping fees
    SHIPPING_BASE: 10, // Should be the same in backend
    SHIPPING_ADD: 1.50, // Should be the same in backend
    TAXE: 5.5,

    // APIs
    API_KEY_MAP: 'AIzaSyAxzWm__9AJGJi12-2lqBr6wxbx1rCRX08',
    PUBLIC_KEY_STRIPE: getPublicKeyStripe(),
}

export function getPublicKeyStripe() {
    return process.env.NODE_ENV !== 'production' ?
        'pk_test_51I6GxdCjzuvkSdCr2YEmoIxTHOggwOreAETR4r8PmV7peWXh7FjakJHJ0KIsfkuDJl7r9HmYXNyFqNR3KwxHdAaF00UJh6cvoC'
        : 'pk_live_51I6GxdCjzuvkSdCrAeunaeP1mmXRwfQJnh3HI1kgbe6c1KLT9bu9GTZvg17ZdLRI90EoZf12HpuL2KkHmD6euvx000hWFfUEFQ';
}