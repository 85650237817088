import { Map } from 'immutable';
import { loadStripe } from '@stripe/stripe-js';

import { PAYMENT } from './PaymentConstants';
import { Config } from '../../config';

const defaultState = () => Map({
    command: Map({}),
    validatingPayment: false,

    purchasing: false,
    errorPurchase: '',
    errorValidation: '',
});

const stripePromise = loadStripe(Config.PUBLIC_KEY_STRIPE);

export default function payment(state = defaultState(), action) {
    switch (action.type) {
        case PAYMENT.VALIDATE_CART_START:
            return state.merge({
                command: Map({}),
                validatingPayment: true,
                errorValidation: '',
            });

        case PAYMENT.VALIDATE_CART_SUCCESS:
            action.command.promise = stripePromise

            return state.merge({
                command: action.command,
                validatingPayment: false,
                errorPurchase: action.error,
            });

        case PAYMENT.VALIDATE_CART_ERROR:
            return state.merge({
                command: Map({}),
                validatingPayment: false,
                errorValidation: action.error,
            });

        case PAYMENT.PURCHASE_START:
            return state.merge({
                errorPurchase: '',
                purchasing: true,
            });

        case PAYMENT.PURCHASE_SUCCESS:
            return state.merge({
                purchasing: false,
            });

        case PAYMENT.PURCHASE_ERROR:
            return state.merge({
                errorPurchase: action.error,
                purchasing: false,
            });

        case PAYMENT.PURCHASE_CLEAN:
            return state.merge({
                command: Map({}),
                purchasing: false,
            });

        default:
            return state;
    }
}