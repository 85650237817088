import { connect } from 'react-redux';

import {
    addProduct,
    fetchProducts,
    updateProduct,
    deleteProduct,
    deleteImage,
    filterByType,
} from '../../modules/products/ProductsActions';
import Products from './Products.jsx';


const mapStateToProps = (state) => {
    const {
        filter,
        creating,
        products,
        paginator,
        fetchingProducts,
    } = state.products.toJS();

    return {
        filter,
        creating,
        products: Object.values(products),
        paginator,
        fetchingProducts,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addProduct(product) {
            dispatch(addProduct(product));
        },
        fetchProducts() {
            dispatch(fetchProducts(true));
        },
        changeFilter(type) {
            dispatch(filterByType(type));
            dispatch(fetchProducts(true));
        },
        updateProduct(id, options) {
            dispatch(updateProduct(id, options));
        },
        deleteProduct(id) {
            dispatch(deleteProduct(id));
        },
        deleteImage(productId, imageName) {
            dispatch(deleteImage(productId, imageName))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);