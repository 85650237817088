import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Spin, Form, Card } from 'antd';

import Input from '../../input/Input';

import './CreateProduct.scss';


class CreateProduct extends Component {
    static propTypes = {
        onClick: PropTypes.func,
        creating: PropTypes.bool,
    };

    state = {
        product: {}
    }

    onChange = (key, value) => {
        const { product } = this.state;
        product[key] = value;
        this.setState({
            product,
        });
    }

    render() {
        const { product } = this.state;
        const {
            onClick,
            creating,
        } = this.props;

        return (
            <div className='CreateProduct'>
                <Spin spinning={creating}>
                    <Form
                        ref={(formRef) => this.formRef = formRef}
                        onFinish={() => onClick(product)}
                    >
                        <Card
                            cover={
                                <img
                                    alt='default background'
                                    src='https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png'
                                />
                            }
                            actions={[
                                <Input
                                    placeholder='Prix'
                                    type='number'
                                    value={product.price}
                                    required
                                    onChange={(value) => this.onChange('price', value)}
                                />,
                                <Input
                                    placeholder='Stock'
                                    type='number'
                                    value={product.stock}
                                    required
                                    onChange={(value) => this.onChange('stock', value)}
                                />,
                                <input type='submit' value='Créer' />,
                            ]}
                        >
                            <div className='CreateProduct-name'>
                                <Input
                                    placeholder='Title'
                                    value={product.name}
                                    onChange={(value) => this.onChange('name', value)}
                                    required
                                />
                                <select
                                    onChange={(event) => {
                                        this.onChange('type', event.target.value)
                                    }}
                                >
                                    <option value='GRES'>GRÈS</option>
                                    <option value='RAKU'>RAKU</option>
                                    <option value='FEU'>GRÈS FEU</option>
                                    <option value='BLACK'>GRÈS BLACK</option>
                                </select>
                            </div>
                            <Input
                                type='textarea'
                                placeholder='Description'
                                className='CreateProduct-textarea'
                                value={product.description}
                                onChange={(value) => this.onChange('description', value)}
                                required
                            />
                        </Card>
                    </Form>
                </Spin>
            </div>
        )
    }
}

export default CreateProduct;