import { push } from 'connected-react-router';

import {
    addItem,
    addShippingFee,
    fetch,
    validate,
    update,
} from './CartClient';
import { CART } from './CartConstants';
import { PAYMENT } from '../payment/PaymentConstants';

export function addToCart(productId, quantity) {
    return (dispatch) => {
        dispatch({ type: CART.ADD_START });

        return addItem(productId, quantity)
            .then(cart => {

                setTimeout(() => {
                    dispatch({
                        type: CART.ADD_SUCCESS,
                        cart: cart,
                    });
                    dispatch(push('/shop'));
                }, 500);
            })
            .catch(error => {
                dispatch({
                    type: CART.ADD_ERROR,
                    error,
                });
            });
    };
}

export function updateCart(articleId, quantity) {
    return (dispatch) => {
        dispatch({ type: CART.UPDATE_START });

        return update(articleId, quantity)
            .then(cart => {
                dispatch({
                    type: CART.UPDATE_SUCCESS,
                    cart: cart,
                });
            })
            .catch(error => {
                dispatch({
                    type: CART.UPDATE_ERROR,
                    error,
                });
            });
    };
}

export function fetchCart() {
    return (dispatch) => {
        dispatch({ type: CART.FETCH_START });

        return fetch()
            .then(cart => {
                dispatch({
                    type: CART.FETCH_SUCCESS,
                    cart,
                });
            })
            .catch(error => {
                dispatch({
                    type: CART.FETCH_ERROR,
                    error,
                });
            });
        };
}

export function shippingFee(country) {
    return (dispatch) => {
        dispatch({ type: CART.ADD_SHIPPING_FEE_START });

        return addShippingFee(country)
            .then(cart => {
                dispatch({
                    type: CART.ADD_SHIPPING_FEE_SUCCESS,
                    cart,
                });
            })
            .catch(error => {
                dispatch({
                    type: CART.ADD_SHIPPING_FEE_ERROR,
                    error,
                });
            });
        };
}

export function validateCart(facturation, expedition, onSuccessCallback) {
    return (dispatch) => {
        dispatch({ type: PAYMENT.VALIDATE_CART_START });

        return validate(facturation, expedition)
            .then(response => {
                dispatch({
                    type: PAYMENT.VALIDATE_CART_SUCCESS,
                    command: response,
                });
                onSuccessCallback()
                // dispatch(push('/payment'));
            })
            .catch(error => {
                dispatch({
                    type: PAYMENT.VALIDATE_CART_ERROR,
                    error: error.response.text,
                });
            });
    };
}

export function cleanCart() {
    return (dispatch) => {
        return dispatch({ type: CART.CLEAN });
    };
}