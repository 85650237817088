import React, { Component } from 'react';
import PropTypes from 'prop-types';

import queryString from 'query-string';
import { Steps } from 'antd';

import Thanks from './thanks/ThanksContainer';
import Payment from './payment/PaymentContainer';
import Validation from './validation/ValidationContainer';
import Information from './information/InformationContainer';

import './Checkout.scss';

const { Step } = Steps;

const STEPS = {
    RÉCAPITULATIF: 0,
    INFORMATIONS: 1,
    PAIEMENT: 2,
    SUCCESS: 3,
}

class Checkout extends Component {
    static propTypes = {
        command: PropTypes.object,
        resetCommand: PropTypes.func,
        changeURL: PropTypes.func,
        loadCommand: PropTypes.func,
        fetchMaintenance: PropTypes.func,
        maintenance: PropTypes.object,
    };

    state = {
        step: STEPS.RÉCAPITULATIF,
    }

    componentDidMount() {
        this.props.fetchMaintenance();
        const uuid = queryString.parse(this.props.location.search)['uuid'];
        if (uuid) {
            this.setState({
                step: STEPS.SUCCESS,
            });
            this.props.loadCommand(uuid);
        }
    }

    componentWillUnmount() {
        this.props.resetCommand();
    }

    nextStep = () => {
        this.setState({
            step: this.state.step + 1,
        });
    }
    backToStep = (nextStep) => {
        const { step } = this.state;
        if (nextStep < step) {
            this.setState({
                step: nextStep,
            });
        }
    }

    renderStepper = () => {
        const { step } = this.state;
        if (step === STEPS.SUCCESS) {
            return false;
        }

        return (
            <div className='Checkout-stepper'>
                <Steps
                    size='small'
                    current={step}
                >
                {
                    Object.keys(STEPS).slice(0, 3).map((key, i) => {
                            return (
                                <Step
                                    key={i}
                                    title={key.toLowerCase()}
                                    onClick={() => this.backToStep(i)}
                                />
                            );
                        })
                    }
                </Steps>
            </div>
        )
    }

    renderContent = () => {
        const {
            command,
            changeURL,
            maintenance,
        } = this.props;

        switch (this.state.step) {
            case STEPS.RÉCAPITULATIF:
                return (
                    <Validation
                        nextStep={this.nextStep}
                        maintenance={maintenance}
                    />
                );
            case STEPS.INFORMATIONS:
                return (
                    <Information
                        nextStep={this.nextStep}
                    />
                );
            case STEPS.PAIEMENT:
                return (
                    <Payment
                        nextStep={() => {
                            this.nextStep();
                            changeURL(command.id);
                        }}
                    />
                );

            case STEPS.SUCCESS:
            default:
                return (
                    <Thanks />
                );
        }
    }

    render() {

        return (
            <div className='Checkout'>
                { this.renderStepper() }
                { this.renderContent() }
            </div>
        )

    }
}
export default Checkout;