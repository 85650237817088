import { enable, disable, fetch, edit } from './MaintenanceClient';
import { MAINTENANCE } from './MaintenanceConstants';


export function editMaintenance(id, description) {
    return (dispatch) => {
        dispatch({ type: MAINTENANCE.EDIT_START });

        return edit(id, description)
            .then(response => {
                return dispatch({
                    type: MAINTENANCE.EDIT_SUCCESS,
                    maintenance: response,
                });
            })
            .catch(error => {
                dispatch({
                    type: MAINTENANCE.EDIT_ERROR,
                    error,
                });
            });
    };
}

export function enableMaintenance(id) {
    return (dispatch) => {
        dispatch({ type: MAINTENANCE.ENABLE_START });

        return enable(id)
            .then(response => {
                return dispatch({
                    type: MAINTENANCE.ENABLE_SUCCESS,
                    maintenance: response,
                });
            })
            .catch(error => {
                dispatch({
                    type: MAINTENANCE.ENABLE_ERROR,
                    error,
                });
            });
    };
}

export function disableMaintenance(id) {
    return (dispatch) => {
        dispatch({ type: MAINTENANCE.DISABLE_START });

        return disable(id)
            .then(response => {
                dispatch({
                    type: MAINTENANCE.DISABLE_SUCCESS,
                    maintenance: response,
                });
            })
            .catch(error => {
                dispatch({
                    type: MAINTENANCE.DISABLE_ERROR,
                    error,
                });
            });
    };
}

export function fetchMaintenance() {
    return (dispatch) => {
        dispatch({ type: MAINTENANCE.FETCH_START });

        return fetch()
            .then(response => {
                dispatch({
                    type: MAINTENANCE.FETCH_SUCCESS,
                    maintenance: response,
                });
            })
            .catch(error => {
                dispatch({
                    type: MAINTENANCE.FETCH_ERROR,
                    error,
                });
            });
    };
}