import {
    deletePro,
    deleteImg,
    getProduct,
    getProducts,
    patchProduct,
    createProduct,
} from './ProductsClient';
import { PRODUCTS } from './ProductsConstants';

export function addProduct(product={}) {
    return (dispatch) => {
        dispatch({ type: PRODUCTS.ADD_START });

        return createProduct(product)
            .then(product => {
                dispatch({
                    type: PRODUCTS.ADD_SUCCESS,
                    product: product,
                });
            })
            .catch(error => {
                dispatch({
                    type: PRODUCTS.ADD_ERROR,
                    error,
                });
            });
    };
}

export function filterByType(filter) {
    return (dispatch) => {
        dispatch({
            type: PRODUCTS.CHANGE_TYPE,
            filter,
        });
    };
}

export function fetchProducts(showAll = false, page = 1) {
    return (dispatch, getState) => {
        dispatch({ type: PRODUCTS.FETCH_START });

        const filter = getState().products.get('filter');

        return getProducts(filter, showAll, page)
            .then(response => {
                dispatch({
                    type: PRODUCTS.FETCH_SUCCESS,
                    products: response.payload,
                    paginator: response.paginator,
                });
            })
            .catch(error => {
                dispatch({
                    type: PRODUCTS.FETCH_ERROR,
                    error,
                });
            });
    };
}

export function fetchProduct(id) {
    return (dispatch) => {
        dispatch({ type: PRODUCTS.FETCH_ONE_START });

        return getProduct(id)
            .then(response => {
                dispatch({
                    type: PRODUCTS.FETCH_ONE_SUCCESS,
                    product: response,
                });
            })
            .catch(error => {
                dispatch({
                    type: PRODUCTS.FETCH_ONE_ERROR,
                    error,
                });
            });
    };
}



export function updateProduct(id, options) {
    return (dispatch) => {
        dispatch({ type: PRODUCTS.UPDATE_START });

        return patchProduct(id, options)
            .then(product => {
                dispatch({
                    type: PRODUCTS.UPDATE_SUCCESS,
                    product: product,
                });
            })
            .catch(error => {
                dispatch({
                    type: PRODUCTS.UPDATE_ERROR,
                    error,
                });
            });
    };
}

export function deleteProduct(id) {
    return (dispatch) => {
        dispatch({
            type: PRODUCTS.DELETE_START,
            id,
        });

        return deletePro(id)
            .then(() => {
                dispatch({
                    type: PRODUCTS.DELETE_SUCCESS,
                });
            })
            .catch(error => {
                dispatch({
                    type: PRODUCTS.DELETE_ERROR,
                    error,
                });
            });
    };
}

export function deleteImage(product, image) {
    return (dispatch) => {
        return deleteImg(product, image)
            .catch(error => {
                console.error(error);
            });
    };
}
