import {
    get,
    fetch,
    update,
} from './CommandsClient';
import { push } from 'connected-react-router';
import { COMMANDS } from './CommandsConstants';

export function fetchCommands(page, filters) {
    return (dispatch) => {
        dispatch({ type: COMMANDS.FETCH_START });

        return fetch(page, filters)
            .then(response => {
                dispatch({
                    type: COMMANDS.FETCH_SUCCESS,
                    commands: response.payload,
                    paginator: response.paginator,
                });
            })
            .catch(error => {
                dispatch({
                    type: COMMANDS.FETCH_ERROR,
                    error,
                });
            });
    };
}

export function fetchCommand(id) {
    return (dispatch) => {
        dispatch({ type: COMMANDS.GET_COMMAND_START });

        return get(id)
            .then(response => {
                dispatch({
                    type: COMMANDS.GET_COMMAND_SUCCESS,
                    command: response,
                });
            })
            .catch(error => {
                dispatch(push('/'))
                return dispatch({
                    type: COMMANDS.GET_COMMAND_ERROR,
                    error,
                });
            });
    };
}

export function updateCommand(id, options) {
    return (dispatch) => {
        dispatch({ type: COMMANDS.UPDATE_START });

        return update(id, options)
            .then(response => {
                dispatch({
                    type: COMMANDS.UPDATE_SUCCESS,
                    command: response,
                });
            })
            .catch(error => {
                dispatch({
                    type: COMMANDS.UPDATE_ERROR,
                    error,
                });
            });
    };
}