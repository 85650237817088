import { UI } from './UIContants';

export function savePosition(value) {
    return (dispatch) => {
        return dispatch({
                type: UI.SAVE_POSITION,
                value,
            });
    }
}
