import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Spin } from 'antd';
import { Elements } from '@stripe/react-stripe-js';

import Cart from '../../../components/cart/Cart';
import Total from '../../../components/total/Total';
import Address from '../../../components/address/Address';
import CheckoutForm from '../../../components/checkoutform/CheckoutForm';

import './Payment.scss';


class Payment extends Component {
    static propTypes = {
        cart: PropTypes.object,
        command: PropTypes.object,
        purchasing: PropTypes.bool,
        errorPurchase: PropTypes.string,
        errorValidation: PropTypes.string,

        validatingPayment: PropTypes.bool,

        onPurchaseStart: PropTypes.func,
        onPurchaseSuccess: PropTypes.func,
        onPurchaseError: PropTypes.func,
        backToHome: PropTypes.func,

        nextStep: PropTypes.func,
    };

    render() {
        const {
            cart,
            command,
            nextStep,
            purchasing,
            errorPurchase,
            onPurchaseError,
            onPurchaseStart,
            onPurchaseSuccess,
            validatingPayment,
        } = this.props;

        if (validatingPayment) {
            return (<Spin />);
        } else if (Object.keys(command).length === 0) {
            return false;
        }
        let [zip, ...city] = command.facturation.city.split(' ');
        city = city.join(' ');

        const clientInfo = {
            name: command.facturation.name,
            email: command.facturation.email,
            address: {
                "city": city,
                "line1": command.facturation.address,
                "postal_code": zip,
              },
        }
        return (
            <Spin spinning={purchasing}>
                <div className='Payment'>
                    <div className='Payment-info'>
                        <h3>Paiement</h3>

                        <div className='Payment-info-addresses'>
                            <div>
                                <h4>Facturation</h4>
                                <Address
                                    city={command.facturation.city}
                                    phone={command.facturation.phone}
                                    name={command.facturation.name}
                                    email={command.facturation.email}
                                    address={command.facturation.address}
                                    country={command.facturation.country}
                                />
                            </div>
                            <div>
                                <h4>Expédition</h4>
                                <Address
                                    city={command.expedition.city}
                                    phone={command.expedition.phone}
                                    name={command.expedition.name}
                                    address={command.expedition.address}
                                    country={command.expedition.country}
                                />
                            </div>
                        </div>

                        <Elements stripe={command.promise} >
                            <CheckoutForm
                                commandId={command.id}
                                clientKey={command.client_secret}
                                clientInfo={clientInfo}
                                purchasing={purchasing}
                                disabled={cart.total === 0}
                                total={cart.total + command.shipping_fee.price}

                                onStart={onPurchaseStart}
                                onSuccess={(commandId) => onPurchaseSuccess(commandId, nextStep)}
                                onError={onPurchaseError}
                            />
                        </Elements>
                        <p className='Payment-error'>{errorPurchase}</p>
                    </div>

                    <div className='Payment-command'>
                        <Cart
                            mini
                            freeze
                            hideHeader
                            cart={cart}
                        />
                        <div className='Payment-command-total'>
                            <Total
                                total={cart.total}
                                shipping={command.shipping_fee.price}
                            />
                        </div>
                    </div>
                </div>
            </Spin>
        )
    }
}
export default Payment;