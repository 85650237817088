import { connect } from 'react-redux';

import {
    cleanCart,
} from '../../../modules/cart/CartActions';
import {
    startPurchase,
    errorPurchase,
    successPurchase,
} from '../../../modules/payment/PaymentActions';

import Payment from './Payment.jsx';


const mapStateToProps = (state) => {
    const {
        cart,
    } = state.cart.toJS();

    const {
        command,
        validatingPayment,
        purchasing,
        errorPurchase,
        errorValidation,
    } = state.payment.toJS();

    return {
        cart,

        command,
        purchasing,
        errorPurchase,
        errorValidation,
        validatingPayment,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onPurchaseStart() {
            dispatch(startPurchase());
        },
        onPurchaseSuccess(commandId, callback) {
            dispatch(successPurchase(commandId, callback));
            dispatch(cleanCart());
        },
        onPurchaseError(error) {
            dispatch(errorPurchase(error));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);