import { connect } from 'react-redux';

import {
    fetchCommands,
    updateCommand,
} from '../../modules/commands/CommandsActions';
import Commands from './Commands.jsx';


const mapStateToProps = (state) => {
    const {
        commands,
        paginator,
        fetchingCommands,
    } = state.commands.toJS();
    return {
        commands,
        paginator,
        fetchingCommands,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCommands(page, filters) {
            dispatch(fetchCommands(page, filters));
        },
        updateCommand(id, options) {
            dispatch(updateCommand(id, options));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Commands);