import { connect } from 'react-redux';

import {
    fetchProduct,
} from '../../modules/products/ProductsActions';
import {
    addToCart,
} from '../../modules/cart/CartActions';
import DetailProduct from './DetailProduct.jsx';


const mapStateToProps = (state) => {
    const pathname = window.location.pathname.split('/');
    const productId = Number.parseInt(pathname[pathname.length - 1])
    const {
        product,
        fetchingProduct,
    } = state.products.toJS();

    const {
        cart,
        adding,
    } = state.cart.toJS();

    return {
        cart,
        adding,
        product,
        productId,
        fetchingProduct,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addToCart(productId, quantity) {
            dispatch(addToCart(productId, quantity));
        },
        fetchProduct(productId) {
            dispatch(fetchProduct(productId));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailProduct);