import Request from 'libs/request';

export function createProduct(product) {
    return Request
        .post(`/api/v0/products/`)
        .send(product)
        .then(result => JSON.parse(result.text));
}

export function getProducts(type, showAll, page) {
    const filterType = type ? `&type=${type}`: '';
    const showAllFilter = showAll ? `&showAll=${showAll}`: '';
    return Request
        .get(`/api/v0/products/?page=${page}${filterType}${showAllFilter}`)
        .then(result => JSON.parse(result.text));
}

export function getProduct(id) {
    return Request
        .get(`/api/v0/products/${id}/`)
        .then(result => JSON.parse(result.text));
}

export function patchProduct(id, options={}) {
    return Request
        .patch(`/api/v0/products/${id}/`)
        .send(options)
        .then(result => JSON.parse(result.text));
}

export function deletePro(id) {
    return Request
        .delete(`/api/v0/products/${id}/`)
        .then(result => result);
}
export function deleteImg(product, image) {
    return Request
        .delete(`/api/v0/products/${product}/del_image/`)
        .send({
            'image': image,
        })
        .then(result => result);
}
