import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Table } from 'antd';
import { EyeOutlined } from '@ant-design/icons';


import './Commands.scss';

const OPTIONS = ['NOT PAID', 'CANCEL', 'PAID', 'DONE'];

class Commands extends Component {
    static propTypes = {
        commands: PropTypes.array,
        paginator: PropTypes.object,
        fetchingCommands: PropTypes.bool,
        updateCommand: PropTypes.func,
        getInvoice: PropTypes.func,
    };

    state = {
        visible: false,
        filters: {
            'status': ['PAID'],
        },
    };

    componentDidMount() {
        this.props.fetchCommands(1, this.state.filters);
    }

    changeFilters = (field, value, checked) => {
        let filters = this.state.filters;
        checked ? filters[field] = value : filters[field] = '';
        this.setState({
            filters,
        });
        this.props.fetchCommands(1, filters);
    }

    getColumns(commands) {
        const { updateCommand } = this.props;
        if (Object.keys(commands).length === 0) {
            return [];
        }
        return [
            {
                title: '#',
                dataIndex: 'id',
                render: (id, item) => {
                    const number = item.facturation && item.facturation.number ? item.facturation.number : id;
                    return (
                        <span>{number}</span>
                    )
                },
            },
            {
                title: 'Date',
                dataIndex: 'date',
            },
            {
                title: 'Nom',
                dataIndex: 'facturation',
                render: (facturation, item) => {
                    return (
                        <a
                            href={`mailto:${facturation.email}`}
                            rel='noreferrer noopener'
                            target='_blank'
                        >
                            {facturation.name}
                        </a>
                    )
                },
            },
            {
                title: 'Total',
                dataIndex: 'total',
                render: (total) => {
                    return (
                        <span>{total} €</span>
                    )
                },
            },
            {
                title: 'Taxes',
                dataIndex: 'shipping_fee',
                render: (shipping_fee) => {
                    return (
                        <span>{shipping_fee.price} €</span>
                    )
                },
            },
            {
                title: 'Status',
                dataIndex: 'status',
                render: (status, item) => {
                    return (
                        <select
                            value={status}
                            onChange={(event) => updateCommand(item.id, {status: event.target.value})}
                        >
                        {
                            OPTIONS.map((option, index) => {
                                return (
                                    <option key={index} value={option} >
                                        {option}
                                    </option>
                                )
                            })
                        }
                        </select>
                    )
                },
            },
            {
                title: 'Open',
                render: (item) => {
                    return (
                    <a href={`/validation?uuid=${item.id}`}
                        type='secondary'
                        target='_blank'
                        rel='noreferrer noopener'
                    >
                        <EyeOutlined />
                    </a>

                )},

            },
        ]
    }

    renderRadio() {
        const { filters } = this.state;
        return (
            <div>
                <h3>Filtres</h3>
                <div className='Commands-filters'>
                {
                    [...OPTIONS, 'ALL'].map((option, index) => {
                        return (
                            <div key={index}>
                                <input
                                    type='radio'
                                    name={'status'}
                                    id={option}
                                    value={option}
                                    onClick={(event) => {
                                        const filtering = event.target.value !== 'ALL' ? event.target.checked : false;
                                        this.changeFilters('status', option, filtering)
                                    }}
                                    defaultChecked={filters['status'].indexOf(option) > -1}
                                />
                                <label htmlFor={option}>{option}</label>
                            </div>

                        )
                    })
                }
                </div>

            </div>
        )
    }

    render() {
        const { filters } = this.state;
        const {
            commands,
            paginator,
            fetchCommands,
            fetchingCommands,
        } = this.props;

        commands.forEach(command => command['key'] = command.id)
        const columns = this.getColumns(commands);

        return (
            <div className='Commands'>
                <h2>Recapitulatif des commandes</h2>
                {this.renderRadio()}

                <div className='Commands-list'>
                    <Table
                        loading={fetchingCommands}
                        columns={columns}
                        dataSource={commands}
                        pagination={{
                            onChange: (page) => fetchCommands(page, filters),
                            showSizeChanger: false,
                            pageSize: paginator.page_size,
                            current: paginator.current,
                            total: paginator.total_elements,
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default Commands;